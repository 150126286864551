import React from "react";
import { useNavigate } from 'react-router-dom';
import { Box, Checkbox, Tooltip, IconButton } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';

import PayoutTable from "../../DataTables/PayoutTables/PayoutTable";
import { fetchCatererPayoutById } from "../../../api/payouts/payoutsApi";

const PaymentDashBoardTable = ({ orders, selectedOrder, onCheckboxChange }) => {
  // Transform the orders array into a format suitable for the table
  console.log("orders in rest", orders);
  const navigate = useNavigate();

  const transformedOrders = orders.map((order) => ({
    id: order?._id || 'N/A', // Fallback if _id is not available
    orderID: order?.orderID || order?.orderId?.orderId || 'N/A', // Fallback if orderID is missing
    custName: order?.caterer?.businessName || order?.caterer?.businessName || 'N/A', // Customer Name fallback
    custContact: order?.caterer?.businessPhoneNumber || order?.caterer?.businessPhoneNumber || 'N/A', // Customer Name fallback
    deliveryAddress: order?.caterer?.address?.addressLine1 || `${order?.caterer?.address?.addressLine1}, ${order?.caterer?.address?.addressLine2}, ${order?.caterer?.address?.landmark} , ${order?.caterer?.address?.city} - ${order?.caterer?.address?.postalCode}`,
    orderDate: new Date(order?.orderId?.createdAt).toLocaleDateString() || order?.orderId?.orderDate ? new Date(order.orderId.orderDate).toLocaleDateString() : 'N/A', // Fallback for missing date
    packageName: order?.orderId?.items?.[0]?.packageId?.packageName || 'N/A', // Fallback for missing package name
    quantity: order?.orderId?.items?.[0]?.guestCount || order?.orderId?.items?.[0]?.quantity || 'N/A', // Fallback for quantity
    totalAmount: order?.totalAmount ? order.totalAmount.toFixed(2) : '0.00', // Fallback for missing total amount
    initialPayment: order?.initialPayment ? order.initialPayment.toFixed(2) : '0.00', // Fallback for missing initial payment
    secondPayment: order?.orderId?.secondPayment ? order.orderId.secondPayment.toFixed(2) : '0.00', // Fallback for second payment
    initialPaymentSchedule: order?.orderId?.initialPaymentSchedule || 'N/A', // Fallback for payment schedule
    initialPaymentDate: order?.orderId?.createdAt ? new Date(order.orderId.createdAt).toLocaleDateString() : 'N/A', // Fallback for initial payment date
    finalPayment: order?.orderId?.finalPayment ? order.orderId.finalPayment.toFixed(2) : '0.00', // Fallback for final payment
    paymentStatus: order?.paymentStatus || 'N/A', // Fallback for payment status
    finalPaymentDueDate: order?.orderId?.finalPaymentDueDate ? new Date(order.orderId.finalPaymentDueDate).toLocaleDateString() : 'N/A', // Fallback for due date
    grossPayout: order?.grossAmount ? order.grossAmount.toFixed(2) : '0.00', // Fallback for gross payout
    fessteFee: order?.fessteFee ? order.fessteFee.toFixed(2) : '0.00', // Fallback for Fesste fee
    gstOnFessteFee: order?.gstOnFessteFee ? order.gstOnFessteFee.toFixed(2) : '0.00', // Fallback for GST on Fesste fee
    transactionFee: order?.transactionFee ? order.transactionFee.toFixed(2) : '0.00', // Fallback for transaction fee
    tds: order?.tds ? order.tds.toFixed(2) : '0.00', // Fallback for TDS
    netPayout: order?.netPayout ? order.netPayout.toFixed(2) : '0.00', // Fallback for net payout
    deliveryFee: order?.orderId?.deliveryFee ? order.orderId.deliveryFee.toFixed(2) : '0.00', // Fallback for delivery fee
    platformFee: order?.orderId?.platformFee ? order.orderId.platformFee.toFixed(2) : '0.00', // Fallback for platform fee
    platformFeeGst: order?.orderId?.platformFeeGst ? order.orderId.platformFeeGst.toFixed(2) : '0.00', // Fallback for platform fee GST
    specialInstructions: order?.orderId?.specialInstructions || 'N/A', // Fallback for special instructions
    waterBottleType: order?.orderId?.waterBottleType ? order.orderId.waterBottleType.join(", ") : 'N/A', // Fallback for water bottle type
    invoiceID: order?.invoiceId || 'N/A', // Fallback for invoice ID
    payoutId: order?._id || 'N/A', // Fallback for payout ID
    catPayoutTransactionId: order?.catPayoutTransactionId || 'N/A', // Fallback for transaction ID
    payoutStatus: order?.status || 'N/A', // Fallback for payout status
    totalOrderPaymentStatus: order?.orderId?.paymentStatus || 'N/A', // Fallback for total order payment status
    initialPayout: order?.initialPayout?.toFixed(2) ? order?.orderId?.initialPayout?.toFixed(2) : 0.00,
    secondPayout: order?.secondPayout?.toFixed(2) ? order?.orderId?.secondPayout?.toFixed(2) : 0.00,
    finalPayout: order?.finalPayout?.toFixed(2) ? order?.orderId?.finalPayout?.toFixed(2): 0.00,
    paymentStage: order?.paymentStage,
    payoutDate:order?.payoutDate,
    secondPayoutDate:order?.secondPayoutDate,
    finalPayoutDate:order?.finalPayoutDate
  }));


  const handleViewDetails = async (orderId) => {
    try {
      const data = await fetchCatererPayoutById(orderId);
      navigate(`/price-summary-payout/${orderId}`, { state: { orderData: data } });
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };


  // Define columns for the table
  const columns = [
    { field: "orderID", headerName: "Order ID", minWidth: 200 },
    { field: "invoiceID", headerName: "Invoice ID", minWidth: 200 },
    { field: "custName", headerName: "Caterer Name", minWidth: 200 },
    { field: "custContact", headerName: "Caterer Contact", minWidth: 200 },
    { field: "deliveryAddress", headerName: "Caterer Address", minWidth: 250 },
    { field: "orderDate", headerName: "Order Date", minWidth: 200 },
    { field: "packageName", headerName: "Package Name", minWidth: 200 },
    { field: "quantity", headerName: "Quantity", minWidth: 200 },
    { field: "grossPayout", headerName: "Gross Payout", minWidth: 200 },
    { field: "netPayout", headerName: "Net Payout", minWidth: 200 },
    { field: "initialPayout", headerName: "Advance Payout", minWidth: 220 },
    { field: "secondPayout", headerName: "Second Payout", minWidth: 200 },
    { field: "finalPayout", headerName: "Final Payout", minWidth: 200 },
    { field: "paymentStage", headerName: "Payout Stage", minWidth: 200 },
    { field: "payoutStatus", headerName: "Payout Status", minWidth: 200 },
    { field: "catPayoutTransactionId", headerName: "Payout Transaction ID", minWidth: 230 },
    { field: "fessteFee", headerName: "Fesste Fee", minWidth: 200 },
    { field: "gstOnFessteFee", headerName: "GST on Fesste Fee", minWidth: 200 },
    { field: "transactionFee", headerName: "Transaction Fee", minWidth: 200 },
    { field: "tds", headerName: "TDS", minWidth: 200 },
   
    {
      field: "pay",
      headerName: "Pay",
      minWidth: 160,
      renderCell: (params) => (
        <Checkbox
          color="primary"
          checked={selectedOrder?._id === params.row.id} // Check if the current row is selected
          onChange={() => onCheckboxChange(params.row)} // Handle checkbox change
        />
      ),
      flex: 0.5,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 60,
      sortable: false,
      renderCell: (params) => (
        <>

          <Tooltip title="View Details">
            <IconButton
              color="default"
              onClick={() => handleViewDetails(params.row.id)}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <Box style={{ height: 400, width: "100%" }}>
      <PayoutTable
        rows={transformedOrders} // Use the transformed data
        columns={columns}
        getRowId={(row) => row.id} // Use _id as row identifier
        pageSize={5}
        disableSelectionOnClick
        autoHeight
      />
    </Box>
  );
};

export default PaymentDashBoardTable;
