import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Button,
  CircularProgress,
  Container,
  Box,
} from "@mui/material";
import AdminDrawer from "../../../../components/AdminDrawer/AdminDrawer";
import { fetchFessteMartPayout } from "../../../../api/payouts/payoutsApi";
import CustomSnackbar from "../../../../components/CustomeSnackBar/CustomSnackbar";
import PayoutTable from "../../../../components/DataTables/PayoutTables/PayoutTable";
import BreadcrumbsComponent from "../../../../components/ResuableComponents/BreadCrumb";

const FessteMartPayouts = () => {
  const navigate = useNavigate();
  const [payouts, setPayouts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Fetch payouts and map the data to required format
  useEffect(() => {
    const getPayouts = async () => {
      setLoading(true);
      try {
        const response = await fetchFessteMartPayout();
        // Map the API response to the format required by the DataGrid
        const mappedPayouts = response.payouts.map((payout) => ({
          id: payout._id, // Unique row ID
          serviceProviderId: payout.serviceProviderId?._id,
          fessteMartId: payout?.fessteMartId?._id,
          spName: payout.serviceProviderId?.spName || "N/A", // Caterer Name
          spPhone: payout.serviceProviderId?.spPhone || "N/A", // Phone Number
          invoiceId: payout.invoiceId || "N/A", // Invoice ID
          amount: payout.amount.toFixed(2), // Total to be Paid
          payoutType: payout.payoutType || "N/A", // Payout Type (e.g., Initial Payment)
          paymentMethod: payout.paymentMethod || "N/A", // Payment Method (e.g., Cash)
          fessteFee: payout.fessteFee?.toFixed(2) || "0.00", // Fesste Fee
          gstOnFessteFee: payout.gstOnFessteFee?.toFixed(2) || "0.00", // GST on Fesste Fee
          transactionFee: payout.transactionFee?.toFixed(2) || "0.00", // Transaction Fee
          totalInitialPayoutAmt:
            payout.totalInitialPayoutAmt?.toFixed(2) || "N/A", // Total Initial Payout Amount
          status: payout.status || "N/A", // Payment Status
          payoutDate: new Date(payout.payoutDate).toLocaleDateString(), // Payout Date
        }));
        setPayouts(mappedPayouts);
        setSnackbar({
          open: true,
          message: "Payouts fetched successfully!",
          severity: "success",
        });
      } catch (error) {
        console.error("Failed to fetch payouts:", error);
        // Do not set the error message; just leave payouts as empty or fetched
      } finally {
        setLoading(false);
      }
    };
    getPayouts();
  }, []);

  const handleMakePayment = (row) => {
    console.log(row);
    navigate(`/payment-dashboard/fmpayouts/${row}`);
  };

  const columns = [
    { field: "spName", headerName: "Service Provider Name", minWidth: 250 },
    { field: "spPhone", headerName: "Phone Number", minWidth: 200 },
    { field: "invoiceId", headerName: "Invoice ID", minWidth: 200 },
    { field: "amount", headerName: "Amount (₹)", minWidth: 200 },
    { field: "payoutType", headerName: "Payout Type", minWidth: 200 },
    { field: "paymentMethod", headerName: "Payment Method", minWidth: 200 },
    { field: "fessteFee", headerName: "Fesste Fee (₹)", minWidth: 200 },
    {
      field: "gstOnFessteFee",
      headerName: "GST on Fesste Fee (₹)",
      minWidth: 240,
    },
    {
      field: "transactionFee",
      headerName: "Transaction Fee (₹)",
      minWidth: 240,
    },
    {
      field: "totalInitialPayoutAmt",
      headerName: "Total Initial Payout (₹)",
      minWidth: 240,
    },
    { field: "status", headerName: "Status", minWidth: 200 },
    { field: "payoutDate", headerName: "Payout Date", minWidth: 200 },
    {
      field: "action",
      headerName: "Action",
      minWidth: 180,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="success"
          onClick={() => handleMakePayment(params.row.fessteMartId)}
        >
          <Typography fontSize="14px" textTransform="none" fontWeight="bold">
            Make Payment
          </Typography>
        </Button>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
      <AdminDrawer>
        <div style={{ padding: "6px" }}>
          <Box display="flex" mb={2}>
            <Typography variant="h4" fontWeight="bolder">
              FessteMart Payout
            </Typography>
            <Box ml={3} mt={1}>
              <BreadcrumbsComponent />
            </Box>
          </Box>

          {loading ? (
            <CircularProgress />
          ) : (
            <Container sx={{ minWidth: "100%" }}>
              {payouts.length === 0 ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100vh"
                >
                  <Typography variant="h6">No payouts available</Typography>
                </Box>
              ) : (
                <PayoutTable rows={payouts} columns={columns} />
              )}
            </Container>
          )}
        </div>
      </AdminDrawer>

      {/* Snackbar for notifications */}
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      />
    </div>
  );
};

export default FessteMartPayouts;
