import React from "react";
import { Box } from "@mui/material";
import PriceSummaryPayout from "../../ResuableComponents/OrderDetailsComponents/PayoutOrderDetails/PriceSummaryPayout";
const PaymentBreakdown = ({ selectedOrder }) => {
  console.log("selectedOrder", selectedOrder);

  // Accessing the orderId data
  const orderIdData = selectedOrder?.orderId || {};

  const paymentData = {
    totalAmount: selectedOrder?.totalAmount,
    initialPayment: selectedOrder?.initialPayment,
    initialPaymentDate: selectedOrder?.initialPaymentDate,
    initialPaymentSchedule: {
      initialPaymentStatus: selectedOrder?.initialPaymentStatus,
      initialPaymentMethod:
        selectedOrder?.initialPaymentSchedule?.initialPaymentMethod, // Fixed
      remainingCashPaymentHandling: null, // New field
      remainingPaymentMethod: null, // New field
      remainingInitialPayment: 0, // New field
      cashPaymentHandling: null, // New field
      initialAmountToBeCollected: null, // New field
    },
    initialPaymentDetails: {
      razorpay_order_id:
        selectedOrder?.initialPaymentDetails?.razorpay_order_id,
    },
    secondPayment: selectedOrder?.secondPayment,
    secondPaymentSchedule: {
      status: selectedOrder?.secondPaymentSchedule?.status,
      secondPaymentMethod:
        selectedOrder?.secondPaymentSchedule?.secondPaymentMethod,
    },
    secondPaymentDueDate: selectedOrder?.secondPaymentDueDate,
    finalPayment: selectedOrder?.finalPayment,
    finalPaymentDueDate: selectedOrder?.finalPaymentDueDate,

    finalPaymentSchedule: {
      status: selectedOrder?.finalPaymentSchedule?.status,
      finalPaymentMethod:
        selectedOrder?.finalPaymentSchedule?.finalPaymentMethod,
    },
    paymentStatus: selectedOrder?.paymentStatus,
    fessteFee: selectedOrder?.fessteFee,
    gstonFessteFee: selectedOrder?.gstOnFessteFee, // Fixed
    transactionFee: selectedOrder?.transactionFee,
    tds: selectedOrder?.tds,
    netPayout: selectedOrder?.netPayout,
    shippingAddress: orderIdData.shippingAddress, // Include any necessary orderId fields
    orderID: selectedOrder?.orderID,
    invoiceID: selectedOrder?.invoiceID,
    payoutId: selectedOrder?.payoutId,
    catPayoutTransactionId: selectedOrder?.catPayoutTransactionId,
    status: selectedOrder?.payoutStatus,
    totalOrderPaymentStatus: selectedOrder?.totalOrderPaymentStatus,
    initialPayout: selectedOrder?.initialPayout,
    secondPayout: selectedOrder?.secondPayout,
    finalPayout: selectedOrder?.finalPayout,
    payoutDate: selectedOrder?.payoutDate,
    secondPayoutDate: selectedOrder?.secondPayoutDate,
    finalPayoutDate: selectedOrder?.finalPayoutDate,
    paymentStage: selectedOrder?.paymentStage
  };

  return (
    <Box mt={4} p={3} bgcolor="#ffffff" borderRadius="8px">
      {/* Price Summary Payout Table */}
      <PriceSummaryPayout paymentData={paymentData} />
    </Box>
  );
};

export default PaymentBreakdown;
