import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { decodeAccessToken } from "../../utils/jwtToken"
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../components/CustomeSnackBar/CustomSnackbar";
import HomePageLanding from "../../assets/images/HomePageLanding.jpg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import baseUrl from "../../config";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

  const handleLogin = async () => {
    if (!emailRegex.test(email)) {
      setSnackbarMessage("Please enter a valid email address");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    if (!passwordRegex.test(password)) {
      setSnackbarMessage(
        "Password must be at least 8 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character"
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await fetch(`${baseUrl}auth/superAdmin/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          password,
          platform: "website",
        }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        localStorage.setItem("userResponse", JSON.stringify(data?.user));


        // Save userId in local storage
        const userId = data?.user?._id; // Assuming `userId` is in `data.user.id`
        if (userId) {
          localStorage.setItem("userId", userId);
        }

        const decodedToken = decodeAccessToken(data.accessToken);
        if (decodedToken) {
          localStorage.setItem("userId", decodedToken.userId); // or however your token contains user info
        }
        if (decodedToken) {
          localStorage.setItem("role", decodedToken.role); // or however your token contains user info
        }

        
        
        setSnackbarMessage("Login successful!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setTimeout(() => {
          setSnackbarOpen(false);
          navigate("/"); // Navigate to home page after snackbar closes
        }, 500);
      } else {
        setSnackbarMessage(data.message || "Login failed");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setSnackbarMessage("Error logging in");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        backgroundImage: `url(${HomePageLanding})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container
        maxWidth="xs"
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          padding: "2rem",
          borderRadius: "12px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.3s ease-in-out",
          "&:hover": {
            transform: "scale(1.02)",
          },
        }}
      >
        <Typography variant="h4" fontWeight="bold" align="center" gutterBottom>
          Login
        </Typography>

        <Typography component="legend" fontWeight="bold" sx={{ mt: 2 }}>
          Email
        </Typography>
        <TextField
          size="small"
          placeholder="Email"
          variant="outlined"
          fullWidth
          margin="dense"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={email && !emailRegex.test(email)}
          helperText={
            email && !emailRegex.test(email)
              ? "Please enter a valid email address"
              : ""
          }
        />

        <Typography component="legend" fontWeight="bold" sx={{ mt: 2 }}>
          Password
        </Typography>
        <TextField
          size="small"
          placeholder="Password"
          variant="outlined"
          type={showPassword ? "text" : "password"}
          fullWidth
          margin="dense"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={password && !passwordRegex.test(password)}
          helperText={
            password && !passwordRegex.test(password)
              ? "Password must be at least 8 characters long, contain an uppercase letter, a lowercase letter, a number, and a special character"
              : ""
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          variant="contained"
          color="success"
          fullWidth
          sx={{ mt: 3, padding: "0.75rem", fontWeight: "bold" }}
          onClick={handleLogin}
        >
          Login
        </Button>
      </Container>

      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />
    </Box>
  );
};

export default Login;
