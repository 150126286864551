import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'; // To get the ID from the URL
import { Paper, Typography, Chip,Grid } from '@mui/material';
import AdminDrawer from '../../components/AdminDrawer/AdminDrawer';
import baseUrl from '../../config';

// API fetch function (You can move this to a separate api file later)
const fetchPromoCodeDetails = async (id) => {
    const response = await fetch(`${baseUrl}promocode/${id}`);
    if (!response.ok) {
        throw new Error('Failed to fetch promo code details');
    }
    return response.json();
};

const PromoCodeDetails = () => {
    const { id } = useParams(); // Extract the promo code ID from the URL
    const [promoCode, setPromoCode] = useState(null); // To store the fetched data
    const [loading, setLoading] = useState(true); // To manage loading state
    const [error, setError] = useState(null); // To manage error state

    useEffect(() => {
        // Fetch promo code details when the component mounts
        const getPromoCodeDetails = async () => {
            try {
                const data = await fetchPromoCodeDetails(id);
                setPromoCode(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        getPromoCodeDetails();
    }, [id]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div style={{ backgroundColor: '#f2f2f2', padding: '20px', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <AdminDrawer>
                <Paper
                    elevation={0}
                    sx={{
                        margin: '0 auto',
                        padding: '20px',
                        backgroundColor: 'white',
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        maxWidth: '800px',
                        width: '100%',
                    }}
                >
                    <Typography variant="h5" component="h1" gutterBottom mb={5}>
                        Promo Code Details
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1"><strong>Code:</strong> {promoCode.code}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1"><strong>Discount Type:</strong> {promoCode.discountType}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1"><strong>Discount Value:</strong> ${promoCode.discountValue}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1"><strong>Minimum Order Value:</strong> ${promoCode.minOrderValue}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1"><strong>Maximum Discount:</strong> ${promoCode.maxAmount}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1"><strong>Admin Contribution:</strong> ${promoCode.adminContri}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1"><strong>Usage Limit:</strong> {promoCode.usageLimit}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1"><strong>Used Count:</strong> {promoCode.usedCount}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1"><strong>User Usage Limit:</strong> {promoCode.userUsageLimit}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1"><strong>Approval Status:</strong> {promoCode.approvalStatus}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1"><strong>Promo Description:</strong> {promoCode.promoDescription}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1"><strong>Available From:</strong> {new Date(promoCode.availableFrom).toLocaleDateString()}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1"><strong>Expiry Date:</strong> {new Date(promoCode.expiryDate).toLocaleDateString()}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1"><strong>Status:</strong> {promoCode.status}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1"><strong>Payment Methods:</strong></Typography>
                            {promoCode.paymentMethods.map((method, index) => (
                                <Chip key={index} label={method} sx={{ marginRight: '5px', marginTop: '5px' }} />
                            ))}
                        </Grid>
                    </Grid>
                </Paper>
            </AdminDrawer>
        </div>
    );
};

export default PromoCodeDetails;
