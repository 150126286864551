// OrderInvoiceHeader.js
import React from "react";
import { Box, Typography } from "@mui/material";

const OrderInvoiceHeader = ({
  invoiceNumber,
  invoiceDate,
  orderNumber,
  restaurantName,
  restaurantAddress,
  restaurantGST,
  transactionId,
  paymentStage,
  payoutDate
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      {/* Left section for Restaurant Details */}
      <Box sx={{ textAlign: "left", flex: 1 }}>
        <Typography
          variant="h4"
          sx={{ color: "#6A1B9A", fontWeight: "bold", mb: 10 }}
        >
          Fesste
        </Typography>
        <Typography variant="body" mb={1}>
          <strong>Tax Invoice on behalf of </strong>
        </Typography>
        <Typography variant="body2">
          <strong>Company Name:</strong> Fesste Infotech Private Limited
        </Typography>
        <Typography variant="body2">
          <strong>Company Address:</strong>{" "}
          <Typography variant="body2">
            No: 10/1, Ramamurthy Nagar Main Road, Doddabanaswadi, Bangalore -
            560043
          </Typography>
        </Typography>

        <Typography variant="body2"><strong>Company GST:</strong> 29ABJCS3745D1Z5</Typography>
        <Typography variant="body2"><strong>Company Contact:</strong> 8904241555</Typography>
      </Box>

      {/* Right section for Invoice Details */}
      <Box sx={{ textAlign: "right", flex: 1, mt: 8 }}>
        <Typography
          variant="h6"
          sx={{ marginBottom: "8px", fontWeight: "bold" }}
        >
          TAX INVOICE
        </Typography>
        <Typography variant="body"><strong>ORIGINAL For Recipient</strong></Typography>
        <Typography variant="body2"><strong>Invoice No:</strong> {invoiceNumber}</Typography>
        <Typography variant="body2">
        <strong>Invoice Date:</strong> {new Date(invoiceDate).toDateString()}
        </Typography>
        <Typography variant="body2"><strong>Order No:</strong> {orderNumber}</Typography>
        {transactionId && (
          <Typography variant="body2">
            <strong>Transaction Id:</strong> {transactionId}
          </Typography>
        )}
        <Typography variant="body2">
        <strong>Payout Stage:</strong>{" "}
          {paymentStage === "initial"
            ? "Initial"
            : paymentStage === "second"
            ? "Second"
            : paymentStage === "final"
            ? "Final"
            : paymentStage}
        </Typography>
        <Typography variant="body2"><strong>Payout Date:</strong> {new Date(payoutDate).toDateString()}</Typography>
      </Box>
    </Box>
  );
};

export default OrderInvoiceHeader;
