import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawer from "../../../../components/AdminDrawer/AdminDrawer";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  IconButton,
  Tooltip,
  Typography,
  styled,
  Paper,
  Snackbar,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box
} from "@mui/material";
import DataTable from "../../../../components/DataTables/DataTable";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { deleteFessteMart } from "../../../../api/servicePartners/fesstemart/fessteMartRegistrationApis";
import CustomSnackbar from "../../../../components/CustomeSnackBar/CustomSnackbar";
import ConfirmDialog from "../../../../components/ResuableComponents/ConfirmDialog";
import { useDialog } from "../../../../hooks/useDialog";
import { renderCellWithMore } from "../../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import baseUrl from "../../../../config";
import BreadcrumbsComponent from "../../../../components/ResuableComponents/BreadCrumb";

const TableContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: "100vh",
  height: "650px",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[0],
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
    height: "auto",
  },
}));

const PartyHall = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();

  const navigate = useNavigate(); // Initialize navigate
  const [formatdata, setFormatData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [snackbarOpenClipCard, setSnackbarOpenClipCard] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
    

    useEffect(() => {
        const fetchPartyHalls = async () => {
            try {
                const response = await fetch(`${baseUrl}partyHallSp`);
                const jsonData = await response.json();

        const transformedData = jsonData.map((partyHall) => ({
          id: partyHall._id,
          partyHallId: partyHall?.partyHallID,
          fessteMartId: partyHall?.fessteMart?._id,
          businessName: partyHall?.businessName,
          businessImage: partyHall?.businessProfilePhoto[0],
          businessPhoneNumber: partyHall?.businessPhoneNumber,
          address: `${partyHall?.address?.addressLine1}, ${partyHall?.address?.addressLine2},${partyHall?.address?.landmark},${partyHall?.address?.city}, ${partyHall?.address?.postalCode},${partyHall?.address?.state},${partyHall?.address?.country}`,
          spName: partyHall?.serviceProvider?.spName,
          spEmail: partyHall?.serviceProvider?.spEmail,
          spPhone: partyHall?.serviceProvider?.spPhone,
          createdOn: partyHall?.createdAt,
        }));

        setFormatData(transformedData);
      } catch (error) {
        console.error("Error fetching PartyHalls:", error);
      }
    };

    fetchPartyHalls();
  }, []);

  const handleEditClick = (rowData) => {
    navigate(`/edit-party-hall/${rowData}`, { state: rowData });
  };

  const handleViewClick = (partyHallId, id) => {
    navigate(`/partyhall-details/${partyHallId}`, { state: { id } });
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    setSnackbarOpenClipCard(true); // Show Snackbar when text is copied
  };

  const handleSnackbarCloseClipCard = () => {
    setSnackbarOpenClipCard(false);
  };


  const handleDelete = async () => {
    try {
      const response = await deleteFessteMart(itemToDelete);

      if (response) {
        setSnackbarSeverity("success");
        setSnackbarMessage(
          response.message || "Fesste Mart deleted successfully"
        );
        // Close the confirmation dialog after successful deletion
        setOpenConfirmDialog(false);

        // Optionally refresh your data or update the formatdata state here
        setFormatData((prevData) =>
          prevData.filter((item) => item.fessteMartId !== itemToDelete)
        );
      } else {
        setSnackbarSeverity("warning");
        setSnackbarMessage(
          response.message || "Operation succeeded but with warnings"
        );
      }
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Failed to delete Fesste Mart");
      console.error("Error during deletion:", error);
    } finally {
      setSnackbarOpen(true); // Show snackbar after API call
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDeleteClick = (fessteMartId) => {
    setOpenConfirmDialog(true);
    // Optionally store fessteMartId to use it after confirmation
    setItemToDelete(fessteMartId);
  };

  const columns = [
    {
      field: "partyHallId",
      headerName: "ID",
      minWidth: 10,
      sortable: false,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleViewClick(params.row.partyHallId, params.row.id)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      field: "businessImage",
      headerName: "Business Image",
      type: "image",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Horizontally center the image
            alignItems: "center", // Vertically center the image
            width: "100%", // Ensure the div takes full width of the cell
            height: "100%", // Ensure the div takes full height of the cell
          }}
        >
          <img
            src={
              params.value !== "N/A"
                ? params.value
                : "placeholder-image-url.jpg"
            } // Placeholder for broken images
            alt="Item"
            style={{
              width: "100px", // Fixed width
              height: "60px", // Fixed height
              borderRadius: "4px", // Rounded corners
              objectFit: "cover", // Cover the area while maintaining aspect ratio
            }}
          />
        </div>
      ),
    },
    {
      field: "businessName",
      headerName: "Business Name",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleViewClick(params.row.partyHallId, params.row.id)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      field: "businessPhoneNumber",
      headerName: "Business Contact",
      minWidth: 250,
      sortable: true,
    },

    {
      field: "spName",
      headerName: "Owner Name",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "spEmail",
      headerName: "Owner Email",
      minWidth: 250,
      sortable: true,
    },

    {
      field: "spPhone",
      headerName: "Owner Contact",
      minWidth: 250,
      sortable: true,
    },
    {
      field: 'address',
      headerName: 'Address',
      minWidth: 350,
      flex: 1, // Makes the column responsive based on available space
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return (
          <Box
            sx={{
              display: 'flex',         // Use flexbox for alignment
              justifyContent: 'space-between', // Align text on the left and icon on the right
              alignItems: 'center',    // Align vertically to the center
              whiteSpace: 'normal',    // Allow the text to wrap to the next line
              wordWrap: 'break-word',  // Break long words into the next line
              overflow: 'visible',     // Ensure the content isn't hidden
              textOverflow: 'clip',    // Avoid ellipsis and display all text
              lineHeight: 1.2,         // Adjust line height for better readability
              height: '100%',          // Ensure the content fills the cell vertically
              padding: '12px 10px',    // Add padding for better spacing within the cell
            }}
          >
            <Box sx={{ flexGrow: 1, paddingRight: '10px' }}>
              {/* Render the address value with "Show More" functionality */}
              {renderCellWithMore(text, handleOpenDialog)}
            </Box>
            <IconButton
              aria-label="copy to clipboard"
              size="small"
              onClick={() => handleCopy(params.value)}  // Trigger copy action
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: "createdOn",
      headerName: "Created On",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      minWidth: 160,
      sortable: false,
      
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>

          <Tooltip title="View">
            <IconButton
              sx={{ color: "#87CEEB", marginRight: "8px" }}
              onClick={() =>
                handleViewClick(params.row.partyHallId, params.row.id)
              }
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              color="success"
              onClick={() => handleEditClick(params.row.id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row.fessteMartId)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      <AdminDrawer>
      <Box display="flex" alignItems="center">
          <Typography variant="h4" fontWeight="bolder" sx={{mr: 3}}>
            Party Halls 
          </Typography>
          <BreadcrumbsComponent />
        </Box>
        <TableContainer>
          <DataTable rows={formatdata} columns={columns} />
          <ConfirmDialog
            open={openConfirmDialog}
            onClose={() => setOpenConfirmDialog(false)}
            onConfirm={handleDelete}
            title="Confirm Deletion"
            content="Are you sure you want to delete this Party hall?"
          />
          <CustomSnackbar
            open={snackbarOpen}
            message={snackbarMessage}
            severity={snackbarSeverity}
            onClose={handleCloseSnackbar}
          />
        </TableContainer>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
        <Snackbar
          open={snackbarOpenClipCard}
          autoHideDuration={2000} // Snackbar closes after 2 seconds
          onClose={handleSnackbarCloseClipCard}
          message="Copied to clipboard!"
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Position of Snackbar
        />
      </AdminDrawer>
    </div>
  );
};

export default PartyHall;
