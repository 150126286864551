import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import {
  IconButton,
  Tooltip,
  Typography,
  styled,
  Paper,
  Snackbar,
  Button,
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DataTable from "../../../components/DataTables/DataTable";
import {
  fetchRestaurants,
  deleteRestaurantById,
} from "../../../api/servicePartners/RestaurantApis";
import ConfirmDialog from "../../../components/ResuableComponents/ConfirmDialog";
import CustomSnackbar from "../../../components/CustomeSnackBar/CustomSnackbar";
import { useDialog } from "../../../hooks/useDialog";
import { renderCellWithMore } from "../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import RestaurantMarketingCell from "../../../components/ResuableComponents/MarkettingOrder/RestaurantMarkettingOrder";
import BASE_URL from "../../../config";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: theme.spacing(2),
}));

const Restaurants = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();

  const navigate = useNavigate();
  const [restaurants, setRestaurants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpenClipCard, setSnackbarOpenClipCard] = useState(false);
  const [marketingOrders, setMarketingOrders] = useState({});

  const handleMarketingOrderChange = (restaurantId, value) => {
    setMarketingOrders((prev) => ({ ...prev, [restaurantId]: value })); // Update the specific restaurantId's value
  };


  // Fetch restaurants from the API
  useEffect(() => {
    const getRestaurants = async () => {
      try {
        const response = await fetchRestaurants();
        const restaurants = response.restaurants || [];

        const mappedData = restaurants.map((item, index) => ({
          id: index + 1,
          restaurantId: item._id,
          serviceProviderId: item.restID,
          businessName: item.restName,
          address: `${item?.restAddress?.addressLine1}, ${item?.restAddress?.addressLine2},${item?.restAddress?.city},${item?.restAddress?.postalCode},`,
          contactNumber: item.managerNumber,
          ownerName: item.serviceProvider?.spName,
          ownerNumber: item.serviceProvider?.spPhone,
          ownerEmail: item.serviceProvider?.spEmail,
          registeredOn: new Date(item.createdAt).toLocaleString(),
          businessImage: item.restaurantImages || "", // Placeholder for restaurant image
          deliveryType: item.deliveryType || "N/A", // Placeholder for delivery type
          marketingOrder: item?.marketingOrder,

        }));
        setRestaurants(mappedData);
        const initialMarketingOrders = {};
        mappedData.forEach((item) => {
          initialMarketingOrders[item.restaurantId] = item.marketingOrder || ""; // Set initial value
        });
        setMarketingOrders(initialMarketingOrders); // Initialize marketingOrders state
      } catch (error) {
        console.error("Failed to fetch restaurants:", error);
        setError("No Restaurants Found");
      } finally {
        setLoading(false);
      }
    };
    getRestaurants();
  }, []);

  const handleAddRestaurantClick = () => {
    navigate("/add-restaurants");
  };

  const handleEditClick = (rowData) => {
    navigate(`/edit-restaurant/${rowData}`, { state: rowData });
  };

  const handleDeleteClick = (rowData) => {
    console.log("rowData", rowData);

    setSelectedRow(rowData);
    setOpenDialog(true);
  };

  const confirmDelete = async () => {
    if (selectedRow) {
      console.log("selectedRow", selectedRow);

      try {
        await deleteRestaurantById(selectedRow);
        // Update the data by filtering out the deleted caterer
        setData(data.filter((row) => row.restaurantId !== selectedRow));
        setSnackbarMessage("Restauarant deleted successfully");
        setSnackbarOpen(true); // Show snackbar for success
      } catch (error) {
        console.error("Error deleting Restaurant:", error);
        setSnackbarMessage("Failed to delete Restaurant");
        setSnackbarOpen(true); // Show snackbar for error
      } finally {
        setOpenDialog(false);
      }
    }
  };

  const handleCloseDialog1 = () => {
    setOpenDialog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    setSnackbarOpenClipCard(true); // Show Snackbar when text is copied
  };

  const handleSnackbarCloseClipCard = () => {
    setSnackbarOpenClipCard(false);
  };


  const handleSubmit = async (restaurantId) => {
    try {
      const response = await fetch(
        `${BASE_URL}dashboard/addTopRestaurants/${restaurantId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ marketingOrder: parseInt(marketingOrders[restaurantId]) }), // Send the correct marketing order
        }
      );

      if (response.ok) {
        setSnackbarMessage("Marketing order updated successfully");
        // Optionally, refetch updated caterers data
      } else {
        setSnackbarMessage("Failed to update marketing order");
      }
    } catch (error) {
      console.error("Error updating marketing order", error);
      setSnackbarMessage("Error updating marketing order");
    } finally {
      setSnackbarOpen(true); // Open snackbar after response
    }
  };

  const columns = [
    { field: "id", headerName: "Id", minWidth: 10, sortable: false },
    {
      field: "serviceProviderId",
      headerName: "Restaurant ID",
      minWidth: 240,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/restaurant-details/${params.row.serviceProviderId}`, {
                state: { restaurantId: params.row.restaurantId },
              })
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      field: "businessImage",
      headerName: "Business Image",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Horizontally center the image
            alignItems: "center", // Vertically center the image
            width: "100%", // Ensure the div takes full width of the cell
            height: "100%", // Ensure the div takes full height of the cell
          }}
        >
          {" "}
          <img
            src={
              params.value !== "N/A"
                ? params.value
                : "placeholder-image-url.jpg"
            } // Placeholder for broken images
            alt="Item"
            style={{
              width: "100px", // Fixed width
              height: "60px", // Fixed height
              borderRadius: "4px", // Rounded corners
              objectFit: "cover", // Cover the area while maintaining aspect ratio
            }}
          />
        </div>
      ),
    },

    {
      field: "businessName",
      headerName: "Restaurant Name",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/restaurant-details/${params.row.serviceProviderId}`, {
                state: { restaurantId: params.row.restaurantId },
              })
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      minWidth: 350,
      flex: 1, // Makes the column responsive based on available space
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return (
          <Box
            sx={{
              display: 'flex',         // Use flexbox for alignment
              justifyContent: 'space-between', // Align text on the left and icon on the right
              alignItems: 'center',    // Align vertically to the center
              whiteSpace: 'normal',    // Allow the text to wrap to the next line
              wordWrap: 'break-word',  // Break long words into the next line
              overflow: 'visible',     // Ensure the content isn't hidden
              textOverflow: 'clip',    // Avoid ellipsis and display all text
              lineHeight: 1.2,         // Adjust line height for better readability
              height: '100%',          // Ensure the content fills the cell vertically
              padding: '12px 10px',    // Add padding for better spacing within the cell
            }}
          >
            <Box sx={{ flexGrow: 1, paddingRight: '10px' }}>
              {/* Render the address value with "Show More" functionality */}
              {renderCellWithMore(text, handleOpenDialog)}
            </Box>
            <IconButton
              aria-label="copy to clipboard"
              size="small"
              onClick={() => handleCopy(params.value)}  // Trigger copy action
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: "contactNumber",
      headerName: "Manager Number",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "ownerName",
      headerName: "Owner Name",
      minWidth: 200,
      sortable: true,
    },

    {
      field: "ownerNumber",
      headerName: "Owner Number",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "ownerEmail",
      headerName: "Owner Email",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    {
      field: "registeredOn",
      headerName: "Registered On",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "deliveryType",
      headerName: "Delivery Type",
      minWidth: 200,
      sortable: true,
    },
    {
      field: 'marketingOrder',
      headerName: 'Marketing Order',
      minWidth: 400,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
          <RestaurantMarketingCell
            restaurantId={params.row.restaurantId}
            marketingOrder={marketingOrders[params.row.restaurantId] || ""}
            handleMarketingOrderChange={handleMarketingOrderChange}
            handleSubmit={handleSubmit}
            snackbarMessage={snackbarMessage}
          />
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Tooltip title="View Details">
            <IconButton
              sx={{ color: "#87CEEB", marginRight: "8px" }}
              onClick={() =>
                navigate(`/restaurant-details/${params.row.serviceProviderId}`, {
                  state: { restaurantId: params.row.restaurantId },
                })
              }
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip color="success" title="Edit">
            <IconButton
              onClick={() => handleEditClick(params.row.restaurantId)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip color="error" title="Delete">
            <IconButton
              onClick={() => handleDeleteClick(params.row.restaurantId)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  console.log("restaurants", restaurants);

  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      <AdminDrawer>
        <Box display="flex" alignItems="center">
          <Typography variant="h4" fontWeight="bolder" sx={{ mr: 3 }}>
            Restaurants
          </Typography>
          <BreadcrumbsComponent />
        </Box>

        {loading ? (
          <Typography>Loading...</Typography>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <Paper
            elevation={0}
            sx={{ padding: "14px", backgroundColor: "#fff" }}
          >
            <ButtonContainer>
              <Button
                variant="contained"
                color="success"
                startIcon={<AddIcon />}
                onClick={handleAddRestaurantClick}
              >
                Add Restaurant
              </Button>
            </ButtonContainer>
            <Container sx={{ minWidth: "100%" }}>
              <DataTable rows={restaurants} columns={columns} />
            </Container>
          </Paper>
        )}
        <ConfirmDialog
          open={openDialog}
          onClose={handleCloseDialog1}
          onConfirm={confirmDelete}
          title="Confirm Deletion"
          content="Are you sure you want to delete this Restaurant?"
        />
        <Snackbar
          open={snackbarOpenClipCard}
          autoHideDuration={2000} // Snackbar closes after 2 seconds
          onClose={handleSnackbarCloseClipCard}
          message="Copied to clipboard!"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Position of Snackbar
        />
        <CustomSnackbar
          open={snackbarOpen}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          severity={
            snackbarMessage.toLowerCase().includes("successfully")
              ? "success"
              : "error"
          }
        />
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default Restaurants;
