import React, { useState, useEffect } from 'react';
import { TextField, Select, MenuItem, FormControl, Typography, Grid, Button, Box, Autocomplete } from '@mui/material';
import Map from '../../../components/Maps/Map';

const stateCityData = {
    Maharashtra: ['Mumbai', 'Pune', 'Nagpur'],
    Karnataka: ['Bengaluru', 'Mysuru', 'Hubli'],
    TamilNadu: ['Chennai', 'Coimbatore', 'Madurai'],
    Delhi: ['New Delhi', 'Dwarka', 'Rohini'],
    UttarPradesh: ['Lucknow', 'Kanpur', 'Agra'],
    WestBengal: ['Kolkata', 'Siliguri', 'Asansol'],
    Rajasthan: ['Jaipur', 'Udaipur', 'Jodhpur'],
    Gujarat: ['Ahmedabad', 'Surat', 'Vadodara'],
    Punjab: ['Chandigarh', 'Amritsar', 'Ludhiana'],
    Haryana: ['Gurugram', 'Faridabad', 'Ambala'],
};

const specializedAreasOptions = [
    'Birthday Party',
    'Corporate Party',
    'Wedding Caterer',
    'Family Party',
    'Friends Party'
];

// const flowerFilterOptions = [
//     'Flowers',
//     'Decorations',
// ];


const CatererForm = ({ onSubmit, initialValues }) => {

    const [selectedaddressType, setSelectedAddressType] = useState('');
    const [selectedAddress, setSelectedAddress] = useState('');
    const [formData, setFormData] = useState({
        ownerName: '',
        mobileNo: '',
        emailID: '',
        deliveryTime: '',
        entityName: '',
        entityPhoneNumber: '',
        addressType: '',
        address: '',
        street: '',
        city: '',
        state: '', // Added state
        postalCode: '',
        serviceRange: '',
        waitTime: '',
        aboutUs: '',
        specializedAreas: [],
        flowerFilter: [],
        panNo: '',
        gstNo: '',
        fssaiNo: '',
        bussinessImage: null,
        panImage: null,
        gstImage: null,
        fssaiImage: null,
        ownerPhoto: null,
        bussinessVideo: null,
    });


    const [errors, setErrors] = useState({
        panNo: '',
        gstNo: '',
        fssaiNo: '',
    });


    // useEffect to update formData when initialValues change
    useEffect(() => {
        if (initialValues) {
            setFormData({
                ownerName: initialValues.ownerName || '',
                mobileNo: initialValues.mobileNo || '',
                emailID: initialValues.emailID || '',  // Note: You had `emailID` in the AddCateres, not `email`
                deliveryTime: initialValues.deliveryTime || '',
                entityName: initialValues.entityName || '',
                entityPhoneNumber: initialValues.entityPhoneNumber || '',
                addressType: initialValues.addressType || '',
                address: initialValues.address || '',
                street: initialValues.street || '',
                city: initialValues.city || '',
                postalCode: initialValues.postalCode || '',
                serviceRange: initialValues.serviceRange || '',
                waitTime: initialValues.waitTime || '',
                aboutUs: initialValues.aboutUs || '',
                specializedAreas: initialValues.specializedAreas || [],
                flowerFilter: initialValues.flowerFilter || [],
                panNo: initialValues.panNo || '',
                gstNo: initialValues.gstNo || '',
                fssaiNo: initialValues.fssaiNo || '',
                bussinessImage: initialValues.bussinessImage || null,
                panImage: initialValues.panImage || null,
                gstImage: initialValues.gstImage || null,
                fssaiImage: initialValues.fssaiImage || null,
                ownerPhoto: initialValues.ownerPhoto || null,
                businessVideo: initialValues.businessVideo || null,
            });
        }
    }, [initialValues]);  // This will re-run when initialValues change

    const handleStateChange = (event) => {
        const selectedState = event.target.value;
        setFormData((prevData) => ({
            ...prevData,
            state: selectedState,
            city: '', // Reset city when state changes
        }));
    };

    const handleCityChange = (event) => {
        const selectedCity = event.target.value;
        setFormData((prevData) => ({
            ...prevData,
            city: selectedCity,
        }));
    };


    const handleAddressTypeChange = (event) => {
        const { value } = event.target;
        setSelectedAddressType(value);
        setFormData((prevData) => ({
            ...prevData,
            addressType: value,
        }));
    };

    const handleAddressChange = (address) => {
        setSelectedAddress(address);
    };


    const handleInputChange = (event) => {
        const { name, value, files } = event.target;

        // Check if the input is a file input
        if (files && files.length > 0) {
            // Handle file input
            setFormData((prevData) => ({
                ...prevData,
                [name]: files[0], // Set the File object
            }));
        } else {
            // Validation logic for text inputs
            if (name === 'panNo' && value && !panRegex.test(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    panNo: 'Invalid PAN format. Expected: AAAAA9999A',
                }));
            } else if (name === 'gstNo' && value && !gstRegex.test(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    gstNo: 'Invalid GST format. Expected: 22AAAAA0000A1Z5',
                }));
            } else if (name === 'fssaiNo' && value && !fssaiRegex.test(value)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fssaiNo: 'Invalid FSSAI number. Expected 14 digits',
                }));
            } else {
                setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
            }

            // Set form data for non-file input
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };


    const handleSpecializedAreasChange = (event, newValue) => {
        setFormData((prevData) => ({
            ...prevData,
            specializedAreas: newValue,
        }));
    };

    // const handleFlowerFilterChange = (event, newValue) => {
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         flowerFilter: newValue,
    //     }));
    // };

    const handleFormSubmit = (event) => {
        event.preventDefault();

        // Check if any errors exist before submitting
        if (Object.values(errors).some((error) => error)) {
            alert("Please fix errors before submitting.");
            return;
        }

        onSubmit(formData);
    };

    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/; // PAN: 5 letters, 4 digits, 1 letter
    const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{3}$/; // GST: 15 alphanumeric characters
    const fssaiRegex = /^[0-9]{14}$/;


    return (
        <form onSubmit={handleFormSubmit}>
            {/* Owner Name and Mobile No */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Owner Name</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Owner Name Here"
                        name="ownerName"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.ownerName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Mobile No</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Mobile Number Here"
                        name="mobileNo"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.mobileNo}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            {/* Email ID and Delivery Time */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Email ID</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Email ID Here"
                        name="emailID"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.emailID}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Delivery in days</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Delivery Time"
                        name="deliveryTime"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.deliveryTime}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            {/* Entity Name and Entity Phone Number */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Entity Name</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Entity Name Here"
                        name="entityName"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.entityName}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Entity Phone Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter Entity Number Here"
                        name="entityPhoneNumber"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.entityPhoneNumber}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            {/* State and City */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend" sx={{ marginBottom: 1.5 }}>State</Typography>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select
                            value={formData.state}
                            onChange={handleStateChange}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>Select State</MenuItem>
                            {Object.keys(stateCityData).map((state) => (
                                <MenuItem key={state} value={state}>{state}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend" sx={{ marginBottom: 1.5 }}>City</Typography>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select
                            value={formData.city}
                            onChange={handleCityChange}
                            displayEmpty
                            disabled={!formData.state}
                        >
                            <MenuItem value="" disabled>Select City</MenuItem>
                            {formData.state &&
                                stateCityData[formData.state].map((city) => (
                                    <MenuItem key={city} value={city}>{city}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>


            </Grid>
            <Grid container spacing={2} style={{ flex: 1 }}>
                <Grid item xs={12} sm={4}>
                    <Typography component="legend">Street</Typography>
                    <FormControl fullWidth variant="outlined" size="small">
                        <TextField
                            fullWidth
                            placeholder="Enter street"
                            name="street"
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                            InputLabelProps={{ shrink: true }}
                            value={formData.street}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Typography component="legend">Postal Code</Typography>
                    <FormControl fullWidth variant="outlined" size="small">
                        <TextField
                            fullWidth
                            placeholder="Enter Postal Code"
                            name="postalCode"
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                            InputLabelProps={{ shrink: true }}
                            value={formData.postalCode}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Typography component="legend">Address Type</Typography>
                    <FormControl fullWidth variant="outlined" size="small">
                        <Select
                            sx={{ marginBottom: 2, marginTop: 1 }}
                            value={selectedaddressType}
                            onChange={handleAddressTypeChange}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>
                                Select Address Type
                            </MenuItem>
                            <MenuItem value="Work">Work</MenuItem>
                            <MenuItem value="Home">Home</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            {/* Address */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} style={{ height: '400px' }}>
                    <Map onAddressChange={handleAddressChange} mapContainerStyle={{ height: '100%' }} />
                </Grid>
                <Grid item xs={12}>
                    <Typography component="legend">
                        Address
                    </Typography>
                    <TextField
                        disabled
                        fullWidth
                        placeholder="Enter Address"
                        name="address"
                        value={selectedAddress}
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
            {/* About Us */}
            <Grid container spacing={2} mb={2} >
                <Grid item xs={12}>
                    <Typography component="legend" sx={{ marginTop: 1.5 }}>About Us</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter About Us"
                        name="aboutUs"
                        variant="outlined"
                        multiline
                        rows={4}
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        InputLabelProps={{ shrink: true }}
                        value={formData.aboutUs}
                        onChange={handleInputChange}
                    />
                </Grid>
            </Grid>

            {/* Specialized Areas */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">Specialized Areas</Typography>
                    <Autocomplete
                        multiple
                        options={specializedAreasOptions}
                        getOptionLabel={(option) => option}
                        value={formData.specializedAreas}
                        onChange={handleSpecializedAreasChange}
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Specialized Areas"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">FSSAI Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter FSSAI Number"
                        name="fssaiNo"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        value={formData.fssaiNo}
                        onChange={handleInputChange}
                        error={!!errors.fssaiNo}
                        helperText={errors.fssaiNo}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                    <Typography component="legend">Flower Filter</Typography>
                    <Autocomplete
                        multiple
                        options={flowerFilterOptions}
                        getOptionLabel={(option) => option}
                        value={formData.flowerFilter}
                        onChange={handleFlowerFilterChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select Flower Filter"
                            />
                        )}
                    />
                </Grid>*/}
            </Grid>


            {/* PAN, GST, FSSAI, and Owner Photo */}
            <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">PAN Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter PAN Number"
                        name="panNo"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        value={formData.panNo}
                        onChange={handleInputChange}
                        error={!!errors.panNo}
                        helperText={errors.panNo}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography component="legend">GST Number</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter GST Number"
                        name="gstNo"
                        variant="outlined"
                        size="small"
                        sx={{ marginBottom: 2, marginTop: 1 }}
                        value={formData.gstNo}
                        onChange={handleInputChange}
                        error={!!errors.gstNo}
                        helperText={errors.gstNo}
                    />
                </Grid>


                <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Business Video</Typography>
                        <input
                            type="file"
                            name="businessVideo"
                            accept="video/*"
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">PAN Image</Typography>
                        <input
                            type="file"
                            name="panImage"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Bussiness Image</Typography>
                        <input
                            type="file"
                            name="bussinessImage"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">GST Image</Typography>
                        <input
                            type="file"
                            name="gstImage"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">FSSAI Image</Typography>
                        <input
                            type="file"
                            name="fssaiImage"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Owner Photo</Typography>
                        <input
                            type="file"
                            name="ownerPhoto"
                            accept="image/*"
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* Submit Button */}
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 2 }}
                >
                    {initialValues ? 'Update' : 'Add'}
                </Button>
            </Box>
        </form>
    );
};

export default CatererForm;
