import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip, Typography, Paper, Button, Container, Skeleton } from '@mui/material';
import DataTable from '../../../components/DataTables/DataTable';
import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import ConfirmDialog from '../../../components/ResuableComponents/ConfirmDialog';
import CustomSnackbar from '../../../components/CustomeSnackBar/CustomSnackbar';
import { deletePackage } from '../../../api/catererPackages/catererPackages';
import baseUrl from '../../../config';

const PackagesPage = () => {
    const [tableData, setTableData] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedPackageId, setSelectedPackageId] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [loading, setLoading] = useState(true); // Track loading state
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseUrl}menu/packages`);
                if (response.ok) {
                    const data = await response.json();

                    const mappedData = data.map((item, index) => ({
                        ...item,
                        siNo: index + 1,
                        id: item._id,
                        packageName: item.packageName,
                        packageType: item.type,
                        packageImage: item?.packageImages,
                        packageAllocatedTo: item.allocatedTo,
                        packagesOrder:item?.packagesOrder,
                        index: index + 1,
                    }));

                    setTableData(mappedData);
                } else {
                    console.error('Failed to fetch data:', response.statusText);
                    setTableData([]);
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setTableData([]);
            } finally {
                setLoading(false); // Stop loading after data is fetched
            }
        };
        fetchData();
    }, []);

    const handleAddClick = () => {
        navigate('/add-caterer-packages');
    };

    const handleEditClick = (id) => {
        navigate(`/edit-caterer-package/${id}`);
    };

    // const handleDeleteClick = (id) => {
    //     setSelectedPackageId(id);
    //     setDialogOpen(true);
    // };

    const handleViewClick = (id) => {
        navigate(`/package-details/${id}`);
    };

    const handleConfirmDelete = async () => {
        try {
            await deletePackage(selectedPackageId); // Use deletePackage function from API file
            setTableData(prevData => prevData.filter(item => item.id !== selectedPackageId));
            setSnackbarMessage('Package deleted successfully');
            setSnackbarSeverity('success');
        } catch (error) {
            setSnackbarMessage('Failed to delete package');
            setSnackbarSeverity('error');
            console.error('Failed to delete package:', error);
        } finally {
            setDialogOpen(false);
            setSelectedPackageId(null);
            setSnackbarOpen(true); // Show snackbar after operation
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div style={{ backgroundColor: '#f2f2f2' }}>
            <Typography variant='h4' fontWeight='bolder' gutterBottom>
                Packages
            </Typography>

            <Paper elevation={0} sx={{ padding: '12px', backgroundColor: '#fff' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Button
                        variant="contained"
                        color="success"
                        startIcon={<AddIcon />}
                        onClick={handleAddClick}
                    >
                        Add Package
                    </Button>
                </div>

                <Container sx={{ minWidth: '100%' }}>
                    {loading ? (
                        // Skeleton loading shimmer while data is being fetched
                        <>
                            {[...Array(5)].map((_, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                                    <Skeleton variant="rectangular" width={100} height={60} style={{ marginRight: '16px' }} />
                                    <Skeleton variant="text" width="30%" />
                                    <Skeleton variant="text" width="20%" style={{ marginLeft: '16px' }} />
                                    <Skeleton variant="text" width="20%" style={{ marginLeft: '16px' }} />
                                </div>
                            ))}
                        </>
                    ) : (
                        <DataTable
                            rows={tableData}
                            columns={[
                                { field: 'siNo', headerName: 'Id', flex: 0.4, sortable: false, headerClassName: 'header-bold', },
                                {
                                    field: 'packageImage',
                                    headerName: 'Package Image',
                                    sortable: false,
                                    minWidth: 180,
                                    renderCell: (params) => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        >
                                            <img
                                                src={params.value !== 'N/A' ? params.value : 'placeholder-image-url.jpg'}
                                                alt="Item"
                                                style={{
                                                    width: '100px',
                                                    height: '60px',
                                                    borderRadius: '4px',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                        </div>
                                    ),
                                },
                                { field: 'packageName', headerName: 'Package Name', flex: 1, sortable: true,
                                    renderCell: (params) => (
                                        <div
                                            onClick={() => handleViewClick(params.row.id)}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {params.value}
                                        </div>
                                    )
                                },
                                { field: 'packageType', headerName: 'Package Type', flex: 0.8, sortable: true },
                                { field: 'packageAllocatedTo', headerName: 'Allocated To', flex: 1, sortable: true },
                                { field: 'packagesOrder', headerName: 'Package Order', flex: 1, sortable: true },
                                { field: 'createdAt', headerName: 'Created At', flex: 1, sortable: true },
                                {
                                    field: 'Actions',
                                    headerName: 'Actions',
                                    minWidth: 150,
                                    sortable: false,
                                    renderCell: (params) => (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                            <Tooltip title="View">
                                                <IconButton sx={{ color: '#87CEEB', marginRight: '8px' }} onClick={() => handleViewClick(params.row.id)}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Edit">
                                                <IconButton color="success" sx={{ marginRight: '8px' }} onClick={() => handleEditClick(params.row.id)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            {/* <Tooltip title="Delete">
                                                <IconButton color="error" onClick={() => handleDeleteClick(params.row.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip> */}
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    )}
                </Container>
            </Paper>

            <ConfirmDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                onConfirm={handleConfirmDelete}
                title="Delete Package"
                content="Are you sure you want to delete this package? This action cannot be undone."
            />
            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
        </div>
    );
};

export default PackagesPage;
