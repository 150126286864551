import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const OrderPriceSummary = ({ totalValue, gstTotal, platformFee, platformFeeGst, deliveryFee }) => {
    const formatCurrency = (value) => `₹${Number(value).toFixed(2)}`; // Format to currency (assuming Indian Rupee)

    const numberToWords = (num) => {
        const singleDigits = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
        const twoDigits = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
        const tensMultiple = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
        const higherPowers = ["", "Thousand", "Lakh", "Crore"];
    
        const convertHundreds = (n) => {
            let str = "";
            if (n > 99) {
                str += `${singleDigits[Math.floor(n / 100)]} Hundred `;
                n = n % 100;
            }
            if (n > 19) {
                str += `${tensMultiple[Math.floor(n / 10)]} `;
                n = n % 10;
            }
            if (n > 0 && n < 10) {
                str += `${singleDigits[n]} `;
            } else if (n >= 10 && n < 20) {
                str += `${twoDigits[n - 10]} `;
            }
            return str.trim();
        };

        const convertNumberToWords = (n) => {
            let str = "";
            let place = 0;

            do {
                const part = n % 1000;
                if (part > 0) {
                    str = `${convertHundreds(part)} ${higherPowers[place]} ${str}`;
                }
                n = Math.floor(n / 1000);
                place++;
            } while (n > 0);

            return str.trim();
        };

        const rupees = Math.floor(num);
        const paise = Math.round((num - rupees) * 100);

        const rupeeText = convertNumberToWords(rupees);
        const paiseText = paise > 0 ? `${convertNumberToWords(paise)} Paise` : "";

        return `${rupeeText} Rupees ${paiseText}`.trim();
    };

    const convertToAmountInWords = (value) => {
        return `${numberToWords(value)} Only`;
    };

    return (
        <Box sx={{ mt: 4, p: 3, border: '1px solid #ccc', borderRadius: 2, backgroundColor: '#f9f9f9' }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                Price Summary
            </Typography>
            <Divider sx={{ mb: 2 }} />

            <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: '500' }}>Order Total:</Typography>
                <Typography variant="body1">{formatCurrency(platformFee)}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: '500' }}>Fesste Fee:</Typography>
                <Typography variant="body1">{formatCurrency(platformFeeGst)}</Typography>
            </Box>
            {/* <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: '500' }}>Delivery Fee:</Typography>
                <Typography variant="body1">{formatCurrency(deliveryFee)}</Typography>
            </Box> */}
            <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: '500' }}>Transaction Fee:</Typography>
                <Typography variant="body1">{formatCurrency(gstTotal)}</Typography>
            </Box>

            <Divider sx={{ my: 2 }} />
            <Box display="flex" justifyContent="space-between" sx={{ mb: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>Total Value:</Typography>
                <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                    {formatCurrency(totalValue)}
                </Typography>
            </Box>

            <Box sx={{ mt: 2 }}>
                <Typography variant="body2" sx={{ fontStyle: 'italic', color: '#555' }}>
                    Amount in Words: {convertToAmountInWords(totalValue)}
                </Typography>
            </Box>
        </Box>
    );
};

export default OrderPriceSummary;
