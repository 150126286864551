import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';

const OrderServiceProviderDetails = ({ businessName, serviceProviderAddress, serviceProviderPhone,serviceProviderGST }) => {

    const fullAddress = `${serviceProviderAddress?.addressLine1 || ''},${serviceProviderAddress?.addressLine2 || ''},${serviceProviderAddress?.landmark || ''}, ${serviceProviderAddress?.city || ''},${serviceProviderAddress?.state || ''},${serviceProviderAddress?.country || ''}, ${serviceProviderAddress?.postalCode || ''}`.replace(/, , /g, ', ').trim();

    return (
        <Box sx={{ mt: 3 }}>
            <Typography variant="h6" fontWeight="bold" gutterBottom>Bill To:</Typography>
            <Typography variant="body2"
            >Fesste Mart Name:{businessName}</Typography>

            <Tooltip title={fullAddress} placement="top" arrow>
                <Typography
                    variant="body2"
                    noWrap
                    sx={{

                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        cursor: 'pointer' // Adds a pointer cursor to indicate interactivity
                    }}
                >
                    FessteMart Address: {fullAddress}
                </Typography>
            </Tooltip>

            <Typography variant='body2'> Mobile Number:{serviceProviderPhone}</Typography>
            <Typography variant='body2'>GST :{serviceProviderGST}</Typography>
        </Box>
    );
};

export default OrderServiceProviderDetails;
