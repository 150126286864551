import React from 'react';
import { Container, Paper, IconButton, Divider } from '@mui/material';
import { Print, Download, ArrowBack } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import OrderInvoiceHeader from './OrderInvoiceHeader';
import OrderCustomerDetails from './OrderCustomerDetails';
import OrderPriceSummary from './OrderPriceSummary';

const RestaurantPayoutInvoice = () => {
    const navigate = useNavigate(); // To handle back navigation
    const { state } = useLocation(); // Access passed state
    const { orderData } = state || {}; // Retrieve order data

    console.log("rest!", orderData);

    const handlePrint = () => {
        window.print();
    };

    const handleDownload = () => {
        const content = document.getElementById('printable-content');
        const excludedElements = document.querySelectorAll('.exclude-from-download');

        excludedElements.forEach(element => {
            element.style.visibility = 'hidden';
        });

        html2canvas(content, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF('p', 'mm', 'a4');
            const imgWidth = 210;
            const pageHeight = 295;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('order-details.pdf');

            excludedElements.forEach(element => {
                element.style.visibility = '';
            });
        });
    };

    return (
        <Container
            sx={{
                padding: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: '#f5f5f5',
                position: 'relative',
            }}
        >
            {/* Back Button */}
            <IconButton
                id="back-button"
                onClick={() => navigate(-1)} // Navigate back to the previous page
                sx={{
                    position: 'absolute',
                    top: 16,
                    left: 16,
                }}
            >
                <ArrowBack />
            </IconButton>

            <IconButton
                id="print-button"
                onClick={handlePrint}
                sx={{
                    position: 'absolute',
                    top: 16,
                    right: 76,
                }}
            >
                <Print />
            </IconButton>

            <IconButton
                id="download-button"
                onClick={handleDownload}
                sx={{
                    position: 'absolute',
                    color: 'green',
                    top: 16,
                    right: 16,
                }}
            >
                <Download />
            </IconButton>

            <Paper id="printable-content" sx={{ padding: 4, width: '100%', maxWidth: '900px', backgroundColor: '#fff' }}>
                <OrderInvoiceHeader
                    invoiceNumber={orderData?.payout?.invoiceId}
                    invoiceDate={new Date(orderData?.payout?.orderId?.createdAt).toDateString()}
                    orderNumber={orderData?.payout?.orderID}
                    restPayoutTransactionId={orderData?.payout?.restPayoutTransactionId}
                    restaurantName={orderData?.payout?.restaurant?.restName}
                    restaurantAddress={orderData?.payout?.restaurant?.restAddress}
                    restaurantGST={orderData?.payout?.restaurant?.managerNumber}
                />

                <OrderCustomerDetails
                    customerName={orderData?.payout?.restaurant?.restName}
                    customerAddress={orderData?.payout?.restaurant?.restAddress}
                    customerPhone={orderData?.payout?.user?.phoneNumber}
                />

                <OrderPriceSummary
                    totalValue={orderData?.payout?.netPayout}
                    platformFeeGst={orderData?.payout?.fessteFee}
                    gstTotal={orderData?.payout?.transactionFee}
                    platformFee={orderData?.payout?.orderId?.totalAmount}
                />
                <Divider sx={{ marginY: 1, borderColor: '#000' }} />
            </Paper>
        </Container>
    );
};

export default RestaurantPayoutInvoice;
