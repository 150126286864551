import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button } from '@mui/material';
import AdminDrawer from '../../components/AdminDrawer/AdminDrawer';
import PayoutTable from '../../components/DataTables/PayoutTables/PayoutTable';

// Sample data for delivery partners
const deliveryPartnerData = [
    { id: 1, name: "John Doe", phoneNumber: "9876543210", totalDeliveries: 25, totalToBePaid: "₹2000" },
    { id: 2, name: "Jane Smith", phoneNumber: "8765432109", totalDeliveries: 15, totalToBePaid: "₹1500" },
    { id: 3, name: "Alice Johnson", phoneNumber: "7654321098", totalDeliveries: 30, totalToBePaid: "₹2500" },
    { id: 4, name: "Bob Brown", phoneNumber: "6543210987", totalDeliveries: 20, totalToBePaid: "₹1800" },
    { id: 5, name: "Charlie Davis", phoneNumber: "5432109876", totalDeliveries: 10, totalToBePaid: "₹800" },
    { id: 6, name: "Diana Evans", phoneNumber: "4321098765", totalDeliveries: 18, totalToBePaid: "₹1400" },
    { id: 7, name: "Evan Wilson", phoneNumber: "3210987654", totalDeliveries: 12, totalToBePaid: "₹1000" },
    { id: 8, name: "Fiona Taylor", phoneNumber: "2109876543", totalDeliveries: 22, totalToBePaid: "₹1800" },
    { id: 9, name: "George Moore", phoneNumber: "1098765432", totalDeliveries: 27, totalToBePaid: "₹2100" },
    { id: 10, name: "Hannah Lee", phoneNumber: "0198765432", totalDeliveries: 24, totalToBePaid: "₹2000" },
];

const DeliveryPartnersPayout = () => {
    const navigate = useNavigate();

    const handleMakePayment = (row) => {
        navigate(`/payment-dashboard/delivery-partner/${encodeURIComponent(row.name)}`);
    };

    const columns = [
        { field: 'name', headerName: 'Delivery Partner Name', flex: 1 },
        { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
        { field: 'totalDeliveries', headerName: 'Total Deliveries', flex: 1 },
        { field: 'totalToBePaid', headerName: 'Total to be Paid', flex: 1 },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="success"
                    onClick={() => handleMakePayment(params.row)}
                >
                    Make Payment
                </Button>
            ),
        },
    ];

    return (
        <div style={{ backgroundColor: '#f2f2f2', height: '100vh' }}>
            <AdminDrawer>
                <div style={{ padding: '24px' }}>
                    <Typography variant='h5' gutterBottom>
                        Delivery Partner Payout
                    </Typography>
                    <PayoutTable
                        rows={deliveryPartnerData}
                        columns={columns}
                    />
                </div>
            </AdminDrawer>
        </div>
    );
};

export default DeliveryPartnersPayout;
