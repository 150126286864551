import * as React from 'react';
import { LineChart, lineElementClasses } from '@mui/x-charts/LineChart';
import { Paper, Typography, useMediaQuery } from '@mui/material';

const deliveredData = [5, 7, 12, 18, 20, 10, 15, 8, 10, 15, 6, 12];
const cancelledData = [2, 4, 8, 10, 15, 6, 8, 18, 20, 10, 15, 12];
const failedData = [1, 3, 4, 7, 10, 5, 6, 8, 10, 15, 6, 12];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export default function DashBoardOrdersReport() {
  // Use media queries to set responsive width
  const isSmallScreen = useMediaQuery('(max-width: 900px)');
  const isMediumScreen = useMediaQuery('(max-width: 1560px)');
  
  // Set chart width based on screen size
  const chartWidth = isSmallScreen ? 300 : isMediumScreen ? 1400 : 1800;

  return (
    <Paper
      elevation={0}
      style={{
        padding: '20px',
        borderRadius: '10px',
        height: '100%',
        backgroundColor: '#ffffff',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Typography variant="h6" style={{ marginBottom: '20px', color: '#555' }}>
        Order Report
      </Typography>
      <div style={{ width: '100%', maxWidth: '100%', overflowX: 'auto' }}>
        <LineChart
          width={chartWidth}
          height={500}
          series={[
            { data: deliveredData, label: 'Delivered', area: true, stack: 'total', showMark: false, color: '#FFD700' },
            { data: cancelledData, label: 'Cancelled', area: true, stack: 'total', showMark: false, color: '#FF4500' },
            { data: failedData, label: 'Failed', area: true, stack: 'total', showMark: false, color: '#00CED1' },
          ]}
          xAxis={[{ scaleType: 'point', data: months }]}
          sx={{
            [`& .${lineElementClasses.root}`]: {
              display: 'none',
            },
          }}
        />
      </div>
    </Paper>
  );
}
