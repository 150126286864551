import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const OrderItemsTable = ({ items }) => {

    console.log('items',items);
    
    const formatCurrency = (value) => `₹${Number(value).toFixed(2)}`; // Format to currency (assuming Indian Rupee)

    return (
        <TableContainer component={Paper} sx={{ mb: 3, borderRadius: 2, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
            <Table>
                <TableHead>
                    <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
                        <TableCell><Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Particulars</Typography></TableCell>
                        <TableCell><Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Qty</Typography></TableCell>
                        <TableCell><Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Price</Typography></TableCell>
                        <TableCell><Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Gross Value</Typography></TableCell>
                        <TableCell><Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Discount</Typography></TableCell>
                        <TableCell><Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>Total</Typography></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item, index) => (
                        <TableRow
                            key={index}
                            sx={{
                                backgroundColor: index % 2 === 0 ? '#fafafa' : '#fff', // Alternating row colors
                                '&:last-child td, &:last-child th': { border: 0 },  // No bottom border for the last row
                            }}
                        >
                            <TableCell>{item.particulars}</TableCell>
                            <TableCell>{item.qty}</TableCell>
                            <TableCell>{item?.price}</TableCell>
                            <TableCell>{formatCurrency(item.grossValue)}</TableCell>
                            <TableCell>{formatCurrency(item.discount)}</TableCell>
                            <TableCell><Typography sx={{ fontWeight: 'bold' }}>{formatCurrency(item.total)}</Typography></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default OrderItemsTable;
