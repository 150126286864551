import React from 'react';
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';

const DeliveryPartnerStatus = ({ statusFilter, setStatusFilter }) => {
    const handleChange = (event) => {
        setStatusFilter(event.target.value);
    };

    return (
        <FormControl size="small" variant="outlined" fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
                sx={{
                    width: '20%',
                }}
                value={statusFilter}
                onChange={handleChange}
                label="Status"
                defaultValue=""
            >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
        </FormControl>
    );
};

export default DeliveryPartnerStatus;
