import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DashboardPage from '../pages/Dashboard/DashboardPage';
import UserManagementPage from '../pages/UserManagement/UserManagementPage';
import Caterers from '../pages/ServiceProviders/Caterers/Caterers';
import AddCaterer from '../pages/ServiceProviders/Caterers/AddCaterer';
import Restaurants from '../pages/ServiceProviders/Restaurants/Restaurants';
import AddRestaurant from '../pages/ServiceProviders/Restaurants/AddRestaurant';
import PartHall from '../pages/ServiceProviders/FessteMart/PartyHall/PartHall';
import AddPartyHall from '../pages/ServiceProviders/FessteMart/PartyHall/AddPartyHall';
import Lightings from '../pages/ServiceProviders/FessteMart/Lightings/Lightings';
import AddLightings from '../pages/ServiceProviders/FessteMart/Lightings/AddLightings';
import AddFlowers from '../pages/ServiceProviders/FessteMart/Flowers/AddFlowers';
import Flowers from '../pages/ServiceProviders/FessteMart/Flowers/Flowers';
import Marquee from '../pages/ServiceProviders/FessteMart/Marquee/Marquee';
import AddMarquee from '../pages/ServiceProviders/FessteMart/Marquee/AddMarquee';
import CatererOrders from '../pages/Orders/CatererOrders/CatererOrders';
import RestaurantOrders from '../pages/Orders/RestaurantOrders/RestaurantOrders';
import PartyHallOrders from '../pages/Orders/FessteMartOrders/PartyHallOrders';
import LightingsOrders from '../pages/Orders/FessteMartOrders/LightingOrders';
import FlowerOrders from '../pages/Orders/FessteMartOrders/FlowerOrders';
import MarqueeOrders from '../pages/Orders/FessteMartOrders/MarqueeOrders';
import DeliveryPartners from '../pages/DeliveryPartners/DeliveryPartners';
import AddDeliveryPartners from '../pages/DeliveryPartners/AddDeliveryPartners';
import MenuList from '../pages/Menus/CaterersMenu/MenuList';
import AddPackages from '../pages/Menus/AddMenus/AddPackages';
import AddSubPackages from '../pages/Menus/AddMenus/AddSubPackages';
import AddItems from '../pages/Menus/AddMenus/AddItems';
import AddAllMenu from '../pages/Menus/CaterersMenu/AddAllMenu';
import CatererReports from '../pages/Reports/ServicePartners/CatererReports';
import RestaurantReports from '../pages/Reports/ServicePartners/RestaurantReports';
import DeliveryReports from '../pages/Reports/DeliveryReports';
import LightingReports from '../pages/Reports/ServicePartners/FessteMartReports/LightingsReports';
import FlowerReports from '../pages/Reports/ServicePartners/FessteMartReports/FlowersReports';
import PartyHallReports from '../pages/Reports/ServicePartners/FessteMartReports/PartyHallReports';
import MarqueeReports from '../pages/Reports/ServicePartners/FessteMartReports/MarqueeReports';
import CaterersPayout from '../pages/Payouts/ServicePartners/CaterersPayout';
import PaymentDashBoard from '../components/DataTables/PayoutTables/PaymentDashBoard';
import RestaurantPayout from '../pages/Payouts/ServicePartners/RestaurantPayout';
import DeliveryPartnersPayout from '../pages/Payouts/DeliveryPartnersPayout';
import FlowersPayout from '../pages/Payouts/ServicePartners/FessteMart/FlowersPayout';
import LightingsPayout from '../pages/Payouts/ServicePartners/FessteMart/LightingsPayout';
import PartyHallPayout from '../pages/Payouts/ServicePartners/FessteMart/PartyHallPayout';
import MarqueePayout from '../pages/Payouts/ServicePartners/FessteMart/MarqueePayout';
import UserDetailsPage from '../pages/UserManagement/UserDetailsPage';
import Roles from '../pages/Roles/Roles';
import AddRoles from '../pages/Roles/AddRoles';
import CustomerQueries from '../pages/Support/CustomerQueries/CustomerQueries';
import DeliveryPartnerQueries from '../pages/Support/DeliveryPartnerQueries/DeliveryPartnerQueries';
import CatererQueries from '../pages/Support/ServicePartnerQueries/CatererQueries';
import RestaurantQueries from '../pages/Support/ServicePartnerQueries/RestaurantQueries';
import PartyHallQueries from '../pages/Support/ServicePartnerQueries/FessteMartQueries/PartyHallQueries';
import MarQueeQueries from '../pages/Support/ServicePartnerQueries/FessteMartQueries/MarqueeQueries';
import FlowersQueries from '../pages/Support/ServicePartnerQueries/FessteMartQueries/FlowersQueries';
import LightingsQueries from '../pages/Support/ServicePartnerQueries/FessteMartQueries/LightingsQueries';
import CaterOrderDetails from '../pages/Orders/CatererOrders/CaterOrderDetails';
import ProtectedRoute from '../utils/ProtectedRoute';
import Login from '../pages/Login/Login';
import Logout from '../pages/Login/Logout';
import PackagesPage from '../pages/Menus/CaterersMenu/PackagesPage';
import PackageDetailsPage from '../pages/Menus/CaterersMenu/PackageDetailsPage/PackageDetailsPage';
import ViewItemDetails from '../pages/Menus/CaterersMenu/ItemDetailsPage/ItemDetailsPage';
import SubPackageDetailsPage from '../pages/Menus/CaterersMenu/SubPackageDetailsPage/SubPackageDetailsPage';
import ServiceProviders from '../pages/ServiceProviders/ServiceProviders';
import RestaurantItemList from '../pages/Menus/RestaurantsMenu/RestaurantItemList';
import AddRestaurantSubPackage from '../pages/Menus/RestaurantsMenu/AddRestaurantSubPackage';
import RestaurantSubPackageDetails from '../pages/Menus/RestaurantsMenu/RestaurantSubPackageDetails/RestaurantSubPackageDetails';
import OrderDetails from '../components/ResuableComponents/OrderDetailsComponents/OrderDetails';
import PromoCodes from '../pages/PromoCodes/PromoCodes';
import AddPromoCodes from '../pages/PromoCodes/AddPromoCodes';
import PromoCodeDetails from '../pages/PromoCodes/PromoCodeDetails';
import CatererDetailsPage from '../pages/ServiceProviders/Caterers/CatererDetailsPage';
import RestaurantDetailsPage from '../pages/ServiceProviders/Restaurants/RestaurantDetailsPage';
import FlowerDetails from '../pages/ServiceProviders/FessteMart/Flowers/flowersDetailPage';
import LightingDetails from '../pages/ServiceProviders/FessteMart/Lightings/LightingDetailsPage';
import PartyHallDetails from '../pages/ServiceProviders/FessteMart/PartyHall/PartyHallDetails';
import MarqueeDetails from '../pages/ServiceProviders/FessteMart/Marquee/MarqueeDetailsPage';
import FessteMartRegistration from '../pages/ServiceProviders/FessteMart/FessteMartRegistration';
import FessteMartQuotations from '../pages/Quotations/FessteMartQuotations';
import FessteQuotationDetails from '../pages/Quotations/FessteQuotationDetails';
import FessteMartPayouts from '../pages/Payouts/ServicePartners/FessteMart/FessteMartPayouts';
import FessteMartOrders from '../pages/Orders/FessteMartOrders/FessteMartOrders';
import FessteMartOrderDetails from '../components/ResuableComponents/OrderDetailsComponents/FessteMartOrderDetails';
import OngoingOrders from '../pages/Orders/OngoingOrders/OngoingOrders';
import PrivacyPolicy from '../pages/ContentManagement/PrivacyPolicy';
import AddPrivacyPolicy from '../pages/ContentManagement/AddPrivacyPolicy';
import OrderInvoice from '../components/ResuableComponents/OrderDetailsComponents/CatererOrderDetails/OrderInvoice';
import RestaurantOrderInvoice from '../components/ResuableComponents/OrderDetailsComponents/RestaurantOrderInvoice/RestaurantOrderInvoice';
import FessteMartInvoice from '../components/ResuableComponents/OrderDetailsComponents/FessteMartOrderInvoice/FessteMartInvoice';
import PayoutInvoice from '../components/ResuableComponents/OrderDetailsComponents/PayoutOrderDetails/PayoutInvoice';
import CaterersDeleted from '../pages/Deleted/CaterersDeleted';
import RestaurantsDeleted from '../pages/Deleted/RestaurantsDeleted';
import LightingsDeleted from '../pages/Deleted/LightingsDeleted';
import FlowersDeleted from '../pages/Deleted/FlowersDeleted';
import MarqueeDeleted from '../pages/Deleted/MarQueeDeleted';
import PartyHallDeleted from '../pages/Deleted/PartyHallDeleted';
import UsersDeleted from '../pages/Deleted/UsersDeleted';
import DeliveryPartnersDeleted from '../pages/Deleted/DeliveryPartnersDeleted';
import PartyHallMenu from '../pages/Menus/FessteMartMenu/PartyHallMenu';
import ViewPartyHalls from '../pages/Menus/FessteMartMenu/FessteMartMenuDetails/ViewPartyHalls';
import LightingsMenu from '../pages/Menus/FessteMartMenu/LightingsMenu';
import ViewLightings from '../pages/Menus/FessteMartMenu/FessteMartMenuDetails/ViewLightings';
import FlowersMenu from '../pages/Menus/FessteMartMenu/FlowersMenu';
import ViewFlowers from '../pages/Menus/FessteMartMenu/FessteMartMenuDetails/ViewFlowers';
import MarQueeMenu from '../pages/Menus/FessteMartMenu/MarQueeMenu';
import ViewMarQuee from '../pages/Menus/FessteMartMenu/FessteMartMenuDetails/ViewMarquee';
import BreadcrumbsComponent from '../components/ResuableComponents/BreadCrumb';
import FessteMartPayoutInvoice from '../components/ResuableComponents/OrderDetailsComponents/PayoutOrderDetails/FessteMartPayoutInvoice';
import RestaurantPaymentDashBoard from '../components/DataTables/PayoutTables/RestaurantPaymentDashBoard';
import RestaurantPayoutInvoice from '../components/ResuableComponents/OrderDetailsComponents/RestaurantOrderPayout/RestaurantPayoutInvoice';
import ViewPolicy from '../pages/ContentManagement/ViewPolicy';
import DeliveryPartnerDetails from '../pages/DeliveryPartners/DeliveryPartnerDetails';
import FessteMartReports from '../pages/Reports/ServicePartners/FessteMartReports/FessteMartReports';
import UsersReports from '../pages/Reports/UserReports';

const RoutingComponent = () => {

  return (
    <Router>
      <Routes>

        {/* Login */}

        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>
          }
        />

        {/* Service Partners */}
        <Route path="/service-providers" element={<ServiceProviders />} />

        <Route path="/caterers" element={<><BreadcrumbsComponent /><Caterers /></>} />
        <Route path='/caterer-details/:id' element={<><BreadcrumbsComponent /><CatererDetailsPage /></>} />
        <Route path="/add-caterers" element={<><BreadcrumbsComponent /><AddCaterer /></>} />
        <Route path="/edit-caterers/:id" element={<><BreadcrumbsComponent /><AddCaterer /></>} />
        <Route path="/restaurants" element={<><BreadcrumbsComponent /><Restaurants /></>} />
        <Route path="/restaurant-details/:id" element={<><BreadcrumbsComponent /><RestaurantDetailsPage /></>} />
        <Route path='/add-restaurants' element={<><BreadcrumbsComponent /><AddRestaurant /></>} />
        <Route path='/edit-restaurant/:id' element={<><BreadcrumbsComponent /><AddRestaurant /></>} />
        <Route path='/party-hall' element={<><BreadcrumbsComponent /><PartHall /></>} />
        <Route path='/add-party-hall' element={<><BreadcrumbsComponent /><AddPartyHall /></>} />
        <Route path='/edit-party-hall/:id' element={<><BreadcrumbsComponent /><FessteMartRegistration /></>} />
        <Route path='/edit-lightings/:id' element={<><BreadcrumbsComponent /><FessteMartRegistration /></>} />
        <Route path='/lightings' element={<><BreadcrumbsComponent /><Lightings /></>} />
        <Route path='/add-lightings' element={<><BreadcrumbsComponent /><AddLightings /></>} />
        <Route path='/edit-lightings/:id' element={<><BreadcrumbsComponent /><AddLightings /></>} />
        <Route path='/flowers' element={<><BreadcrumbsComponent /><Flowers /></>} />
        <Route path='/add-flowers' element={<><BreadcrumbsComponent /><AddFlowers /></>} />
        <Route path='/edit-flowers/:id' element={<><BreadcrumbsComponent /><FessteMartRegistration /></>} />
        <Route path='/marquee' element={<><BreadcrumbsComponent /><Marquee /></>} />
        <Route path='/add-marquee' element={<><BreadcrumbsComponent /><AddMarquee /></>} />
        <Route path="/edit-marquee/:id" element={<><BreadcrumbsComponent /><FessteMartRegistration /></>} />
        <Route path='/flowers-details/:id' element={<><BreadcrumbsComponent /><FlowerDetails /></>} />
        <Route path='/lighting-details/:id' element={<><BreadcrumbsComponent /><LightingDetails /></>} />
        <Route path='/partyhall-details/:id' element={<><BreadcrumbsComponent /><PartyHallDetails /></>} />
        <Route path='/marquee-details/:id' element={<><BreadcrumbsComponent /><MarqueeDetails /></>} />
        <Route path='/fesste-mart-registration' element={<><BreadcrumbsComponent /><FessteMartRegistration /></>} />

        {/*  Menus */}
        <Route path='/caterers-menu' element={<MenuList />} />
        <Route path='/add-caterer-menus' element={<AddAllMenu />} />

        <Route path='/caterer-packages' element={<PackagesPage />} />
        <Route path='/add-caterer-packages' element={<AddPackages />} />
        <Route path="/edit-caterer-package/:id" element={<AddPackages />} />
        <Route path="/package-details/:id" element={<PackageDetailsPage />} />

        <Route path='/add-caterer-sub-packages' element={<AddSubPackages />} />
        <Route path="/edit-subpackages/:id" element={<AddSubPackages />} />
        <Route path="/view-subpackage-details/:id" element={<SubPackageDetailsPage />} />

        <Route path='/add-caterer-items' element={<AddItems />} />
        <Route path='/edit-caterer-item/:id' element={<AddItems />} />
        <Route path='/view-item-details/:id' element={<ViewItemDetails />} />



        {/* Restaurant Menus */}
        <Route path='/restaurants-menu' element={<RestaurantItemList />} />
        <Route path='/addRestaurantSubPackage' element={<AddRestaurantSubPackage />} />
        <Route path='/editRestaurantSubPackage/:id' element={<AddRestaurantSubPackage />} />
        <Route path='/viewRestaurantSubPackage/:id' element={<RestaurantSubPackageDetails />} />

        {/* FessteMart Menus */}
        <Route path="/party-hall-menu" element={<PartyHallMenu />} />
        <Route path="/view-party-halls/:id" element={<ViewPartyHalls />} />
        <Route path="/lightings-menu" element={<LightingsMenu />} />
        <Route path='/view-lightings-menu/:id' element={<ViewLightings />} />
        <Route path="/flowers-menu" element={<FlowersMenu />} />
        <Route path='/view-flowers-menu/:id' element={<ViewFlowers />} />
        <Route path='/marquee-menu' element={<MarQueeMenu />} />
        <Route path='/view-marquee-menu/:id' element={<ViewMarQuee />} />

        {/* Orders */}
        <Route path="/ongoing-orders" element={<OngoingOrders />} />
        <Route path='/caterer-orders' element={<CatererOrders />} />
        <Route path="/order-details/:orderId" element={<CaterOrderDetails />} />
        <Route path="/caterer-order-invoice/:id" element={<OrderInvoice />} />
        <Route path="/restaurant-order-invoice/:id" element={<RestaurantOrderInvoice />} />
        <Route path="/fessteMart-order-invoice/:id" element={<FessteMartInvoice />} />
        <Route path='/restaurant-orders' element={<RestaurantOrders />} />
        <Route path='/party-hall-orders' element={<PartyHallOrders />} />
        <Route path='/lightings-orders' element={<LightingsOrders />} />
        <Route path='/flower-orders' element={<FlowerOrders />} />
        <Route path='/marquee-orders' element={<MarqueeOrders />} />
        <Route path="/restaurant-order-invoice/:orderId" element={<OrderDetails />} />
        <Route path='/fesste-mart-orders' element={<FessteMartOrders />} />
        <Route path='/fessteMart-order-details/:id' element={<FessteMartOrderDetails />} />

        {/* Delivery Partners */}
        <Route path='/delivery-partner-list' element={<DeliveryPartners />} />
        <Route path='/add-delivery-partners' element={<AddDeliveryPartners />} />
        <Route path='/edit-delivery-partners/:id' element={<AddDeliveryPartners />} />
        <Route path='/delivery-partner-details/:id' element={<DeliveryPartnerDetails />} />

        {/* Payouts */}
        <Route path="/caterers-payout" element={<CaterersPayout />} />
        <Route path="/restaurant-payout" element={<RestaurantPayout />} />
        <Route path="/flowers-payout" element={<FlowersPayout />} />
        <Route path="/lightings-payout" element={<LightingsPayout />} />
        <Route path="/price-summary-payout/:id" element={<PayoutInvoice />} />
        <Route path="/restaurant-price-summary-payout/:id" element={<RestaurantPayoutInvoice />} />
        <Route path="/fessteMart-price-summary-payout/:id" element={<FessteMartPayoutInvoice />} />
        <Route path="/party-hall-payout" element={<PartyHallPayout />} />
        <Route path="/marquee-payout" element={<MarqueePayout />} />
        <Route path="/delivery-partners-payout" element={<DeliveryPartnersPayout />} />
        <Route path="/payment-dashboard/:type/:id" element={<PaymentDashBoard />} />
        <Route path="/fessteMart-payouts" element={<FessteMartPayouts />} />
        <Route path="/restaurant-payment-dashboard/:id" element={<RestaurantPaymentDashBoard />} />

        {/*  users */}
        <Route path="/users" element={<UserManagementPage />} />
        <Route path="/user-details/:id" element={<UserDetailsPage />} />

        {/* PromoCodes */}

        <Route path="/promoCodes" element={<PromoCodes />} />
        <Route path="/add-caterer-promoCodes" element={<AddPromoCodes />} />
        <Route path="/add-restaurant-promoCodes" element={<AddPromoCodes />} />
        <Route path="/edit-caterer-promoCodes/:id" element={<AddPromoCodes />} />
        <Route path="/edit-restaurant-promocodes/:id" element={<AddPromoCodes />} />
        <Route path="/view-caterer-promoCodes/:id" element={<PromoCodeDetails />} />
        <Route path="/view-restaurant-promoCodes/:id" element={<PromoCodeDetails />} />


        {/* Reports */}
        <Route path="/caterers-report" element={<CatererReports />} />
        <Route path="/restaurant-reports" element={<RestaurantReports />} />
        <Route path="/delivery-partner-reports" element={<DeliveryReports />} />
        <Route path="/lightings-reports" element={<LightingReports />} />
        <Route path="/flowers-reports" element={<FlowerReports />} />
        <Route path="/party-hall-reports" element={<PartyHallReports />} />
        <Route path="/marquee-reports" element={<MarqueeReports />} />
        <Route path="/fesstemart-reports" element={<FessteMartReports />} />
        <Route path='/user-reports' element={<UsersReports />} />

        {/* Roles */}
        <Route path='/roles' element={<Roles />} />
        <Route path='/add-roles' element={<AddRoles />} />
        <Route path="/edit-roles/:_id" element={<AddRoles />} />


        {/* Quotations */}
        <Route path='/quotations' element={<FessteMartQuotations />} />
        <Route path='/view-quotation/:id' element={<FessteQuotationDetails />} />

        {/* Support */}
        <Route path='/customer-queries' element={<CustomerQueries />} />
        <Route path='/delivery-partner-queries' element={<DeliveryPartnerQueries />} />
        <Route path='/caterer-queries' element={<CatererQueries />} />
        <Route path='/restaurant-queries' element={<RestaurantQueries />} />
        <Route path='/party-hall-queries' element={<PartyHallQueries />} />
        <Route path='/marquee-queries' element={<MarQueeQueries />} />
        <Route path='/flowers-queries' element={<FlowersQueries />} />
        <Route path='/lightings-queries' element={<LightingsQueries />} />


        {/* Deleted */}
        <Route path="/caterers-deleted" element={<CaterersDeleted />} />
        <Route path="/restaurant-deleted" element={<RestaurantsDeleted />} />
        <Route path="/party-hall-deleted" element={<PartyHallDeleted />} />
        <Route path="/lightings-deleted" element={<LightingsDeleted />} />
        <Route path="/flowers-deleted" element={<FlowersDeleted />} />
        <Route path="/marquee-deleted" element={<MarqueeDeleted />} />
        <Route path="/users-deleted" element={<UsersDeleted />} />
        <Route path="/delivery-partner-deleted" element={<DeliveryPartnersDeleted />} />

        {/* Content Management */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/add-privacy" element={<AddPrivacyPolicy />} />
        <Route path="/view-policy/:id" element={<ViewPolicy />} />

      </Routes>
    </Router>
  );

}

export default RoutingComponent;
