import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
} from "@mui/material";
import AdminDrawer from "../../../components/AdminDrawer/AdminDrawer";
import PayoutTable from "../../../components/DataTables/PayoutTables/PayoutTable";
import baseUrl from "../../../config";
import { useDialog } from "../../../hooks/useDialog";
import { renderCellWithMore } from "../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup";
import BreadcrumbsComponent from "../../../components/ResuableComponents/BreadCrumb";

const RestaurantPayout = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();
  const navigate = useNavigate();
  const [restaurantData, setRestaurantData] = useState([]); // State for restaurant data
  const [loading, setLoading] = useState(true); // State for loading status

  useEffect(() => {
    const fetchPayouts = async () => {
      try {
        const response = await fetch(`${baseUrl}restaurantPayouts`);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        // Map the fetched data to the format required for the table
        const formattedData = data.map((item, index) => ({
          id: item?._id, // You might want to adjust this according to your data structure
          siNo: index + 1,
          restaurantId: item?.restaurant?._id,
          restID: item?.restaurant?.restID,
          name: item?.restaurant?.restName, // Assuming restaurantId is the name; adjust accordingly
          phoneNumber: item?.restaurant?.managerNumber, // Adjust based on actual structure
          totalAmount: item.orderId.totalAmount, // Count of items as total orders; adjust as needed
          totalToBePaid: `₹${(Math.round(item.netPayout * 100) / 100).toFixed(
            2
          )}`, // Format grossAmount as needed
        }));
        setRestaurantData(formattedData);
      } catch (error) {
        console.error(error); // Optionally log the error
        setRestaurantData([]); // Set to empty array instead of error
      } finally {
        setLoading(false);
      }
    };

    fetchPayouts();
  }, []); // Empty dependency array to run effect once on mount

  const handleMakePayment = (row) => {
    navigate(
      `/restaurant-payment-dashboard/${encodeURIComponent(row.restaurantId)}`
    );
  };

  const columns = [
    { field: "siNo", headerName: "Si No", flex: 1 },
    { field: "restID", headerName: "Restaurant ID", flex: 1 },
    {
      field: "name",
      headerName: "Restaurant Name",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return renderCellWithMore(text, handleOpenDialog);
      },
    },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
    { field: "totalAmount", headerName: "Total Amount", flex: 1 },
    { field: "totalToBePaid", headerName: "Total to be Paid", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="success"
          onClick={() => handleMakePayment(params.row)}
        >
          Make Payment
        </Button>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#f2f2f2", height: "100vh" }}>
      <AdminDrawer>
        <div style={{ padding: "24px" }}>
          <Box display="flex"> 
            <Typography variant="h5" gutterBottom>
              Restaurant Payout
            </Typography>
            <Box ml={3} mt={0.5}>
              <BreadcrumbsComponent />
            </Box>
          </Box>

          {loading ? (
            <Typography variant="h6">Loading...</Typography>
          ) : restaurantData.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100vh"
            >
              <Typography variant="h6">No restaurant payout found</Typography>
            </Box>
          ) : (
            <PayoutTable rows={restaurantData} columns={columns} />
          )}
        </div>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default RestaurantPayout;
