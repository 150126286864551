// src/api/restaurantorders.js
import baseUrl from "../../config";

export const fetchCatererOrders = async () => {
  try {
    const response = await fetch(`${baseUrl}getAllCatererOrders`);
    const data = await response.json();
    console.log(data);
    
    // Map the API response to match the columns
    const mappedData = data.map((order, index) => ({
      id: order?._id || "N/A",
      siNo: index + 1,
      catererId: order?.catererId?._id || " ",
      orderId: order?.orderID || " ",
      customerName: order?.userId?.fullName || " ",
      customerContact : order?.userId?.phoneNumber,
      orderType: order.status === "Delivered" ? "Delivery" : "Pickup",
      deliveryPerson: "N/A",
      caterer: order?.catererId?.businessName || " ",
      status: order?.status || "N/A",
      packageType: order?.items?.[0]?.packageId?.packageName || "N/A",
      quantity: order?.items?.[0]?.guestCount || "N/A",
      deliveryDate: order?.items?.[0]?.deliveryDate
        ? new Date(order.items[0].deliveryDate).toLocaleString() // Convert to locale string
        : "N/A",
      address: `${order?.catererId?.address?.addressLine1 || ""}, ${
        order?.catererId?.address?.city || ""
      }, ${order?.catererId?.address?.state || ""} - ${
        order?.catererId?.address?.postalCode || ""
      }`,
      deliveryType: order?.deliveryType || "N/A",
      deliveryStatus: order?.deliverystatus,
      rejectReason: order?.rejectReason || "N/A",
      paymentStatus: order?.finalPaymentSchedule?.status || "N/A",
      date: order?.createdAt
        ? new Date(order.createdAt).toLocaleDateString()
        : "N/A",
      shippingAddress: `${order?.shippingAddress?.street || ""}, ${
        order?.shippingAddress?.city || ""
      }, ${order?.shippingAddress?.state || ""} - ${
        order?.shippingAddress?.postalCode || ""
      }`,
      paymentMode: order?.paymentMethod || "N/A",
      total: `₹${order?.grossAmount || 0}`,
      totalAmount: `₹${order?.totalAmount || 0}`,
      orderDate: order?.createdAt
        ? new Date(order.createdAt).toLocaleDateString()
        : "N/A",
      deliveredBy: order?.deliveryPerson || "Not Assigned",
      discountObtained: order?.discountAmount || 0,
      couponCodeUsed: order?.couponCode || "None",
      discountAmount: order?.discountAmount || 0,
      userId: order?.userId?._id || "N/A",
      deliveryPartnerId: order?.deliveryPartnerId?.[0]?._id,
      deliveryPartnerName: order?.deliveryPartnerId?.[0]?.fullName,
      deliveryPartnerContact: order?.deliveryPartnerId?.[0]?.phoneNumber,
      pickUpOtp: order?.pickUpOtp
    }));

    return mappedData;
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error;
  }
};

// src/api/orders/orderDetails.js
export const fetchCatererOrderDetails = async (orderId) => {
  const response = await fetch(`${baseUrl}catererOrder/${orderId}`);
  if (!response.ok) {
    throw new Error("Failed to fetch order details");
  }
  const data = await response.json();
  return data;
};

//  adding caterer delivery type
export const updateDeiveryType = async (orderData) => {
  try {
    const response = await fetch(`${baseUrl}/updateDeliveryType`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderData),
    });

    if (!response.ok) {
      throw new Error("Failed to Update Delivery Type");
    }

    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
