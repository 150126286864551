import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled, Paper, TextField, IconButton, Typography} from '@mui/material';
import { Download as DownloadIcon } from '@mui/icons-material';
import { jsonToCSV } from 'react-papaparse';
import debounce from 'lodash/debounce';
import DeliveryPartnerStatus from '../../components/DataTables/DeliveryPartnerStatus';

const TableContainer = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    maxHeight: 'calc(100vh - 100px)',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[0],
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
    },
}));

const SearchContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'stretch',
    },
}));

const NoRowsMessage = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
}));

const AdvancedDataTable = ({ rows, columns, onAddClick }) => {
    const [searchText, setSearchText] = useState('');
    const [filteredRows, setFilteredRows] = useState(rows);
    const [statusFilter, setStatusFilter] = useState('');

    const filterRows = useCallback(
        debounce((searchValue, statusValue) => {
            let data = rows;
            if (searchValue !== '') {
                const value = searchValue.toLowerCase();
                data = data.filter(row =>
                    columns.some(column =>
                        row[column.field]?.toString().toLowerCase().includes(value)
                    )
                );
            }
            if (statusValue !== '') {
                data = data.filter(row => row.status === statusValue);
            }
            setFilteredRows(data);
        }, 300),
        [rows, columns]
    );

    useEffect(() => {
        filterRows(searchText, statusFilter);
    }, [searchText, statusFilter, filterRows]);

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const handleDownloadCSV = () => {
        try {
            const csvData = jsonToCSV(filteredRows);
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.setAttribute('download', 'data.csv');
            link.click();
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error generating CSV:', error);
        }
    };

    return (
        <TableContainer>
            <SearchContainer>
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search..."
                    value={searchText}
                    onChange={handleSearch}
                    sx={{
                        width: '300px',
                        marginRight: '10px',
                    }}
                />
                <DeliveryPartnerStatus statusFilter={statusFilter} setStatusFilter={setStatusFilter} />

                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        onClick={handleDownloadCSV}
                        color="primary"
                        sx={{ marginRight: '8px' }}
                    >
                        <DownloadIcon sx={{ color: 'green' }} />
                    </IconButton>

                </div>
            </SearchContainer>
            {filteredRows?.length === 0 ? (
                <NoRowsMessage>No matching rows found.</NoRowsMessage>
            ) : (
                <div style={{ flexGrow: 1, overflow: 'auto' }}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <DataGrid
                            rows={filteredRows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10, 25, 50]}
                            style={{ height: '100%', width: '100%' }}
                        />
                    </div>
                </div>
            )}
        </TableContainer>
    );
};

export default AdvancedDataTable;
