// src/utils/notificationService.js
import { messaging } from "./firebaseConfig";
import { getToken } from "firebase/messaging";
import BASE_URL from '../../config';

// Function to request notification permission and handle token
export async function requestNotificationPermission() {
  try {
    // Check if permission has already been granted to avoid multiple prompts
    const existingPermission = localStorage.getItem('notificationPermission');
    if (existingPermission === 'granted') {
      console.log("Notification permission already granted.");
    } else {
      const permission = await Notification.requestPermission();
      localStorage.setItem('notificationPermission', permission);

      if (permission !== "granted") {
        console.log("Notification permission not granted by the user.");
        return;
      }
    }

    // Get existing FCM token from localStorage if it exists
    let token = localStorage.getItem('fcmToken');
    if (!token) {
      // Get FCM token if not already saved
      token = await getToken(messaging, {
        vapidKey: "BI2K8yeKFBIYUjvQF68RYPq177SEWfnoU1_ifTp-JoxK6HkGA2L8W7kNR7UJYCq_NszO8sKpgsOmhL5v-my6vk4",
      });

      if (token) {
       
        localStorage.setItem('fcmToken', token); // Save token to prevent re-sending
        await sendTokenToBackend(token);
      } else {
        console.log("No token available.");
      }
    } else {
      console.log("FCM token already exists.");
    }
  } catch (error) {
    console.error("Failed to request notification permission", error);
  }
}

// Function to send the token to your backend
async function sendTokenToBackend(token) {
  try {
    const accessToken = localStorage.getItem('accessToken');

    if (!accessToken) {
      throw new Error("Access token not found in localStorage.");
    }

    const response = await fetch(`${BASE_URL}admin/savefcm`, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({ fcmToken: token }), 
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to send token to backend: ${response.status} ${response.statusText} - ${errorText}`);
    }

    console.log('Token sent to backend successfully.');
  } catch (error) {
    console.error('Error sending token to backend:', error);
  }
}
