import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

const DashboardStatistics = () => {
    // State to hold the statistics data fetched from the API
    const [stats, setStats] = useState({
        totalCaters: 0,
        totalRestaurants: 0,
        totalDeliveryPartners: 0,
        totalCustomers: 0,
        fessteMart: 0,
        totalEarnings: 0,
        todayEarnings: 0, // corrected here
        totalAdminCommission: 0,
        totalRestaurantCommission: 0,
        totalDeliveryBoyCommission: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://admin.fesste.com/api/dashboard/analytics');
                const data = await response.json();
                
                // Update state with fetched data, using optional chaining in case some properties are missing
                setStats({
                    totalCaters: data?.data?.totalCaterers ?? 0, // Defaulting to 0 if data.totalCaterers is undefined
                    totalRestaurants: data?.data?.totalRestaurant ?? 0,
                    totalDeliveryPartners: data?.data?.deliveryPartner ?? 0,
                    totalCustomers: data?.data?.totalCustomers ?? 0,
                    fessteMart: data?.data?.totalFessteMart ?? 0,
                    totalEarnings: data?.data?.totalEarnings ?? 0,
                    todayEarnings: data?.data?.todayTotalEarnings ?? 0,
                    totalAdminCommission: data?.data?.totalAdminCommission ?? 0,
                    totalRestaurantCommission: data?.data?.totalRestaurantCommission ?? 0,
                    totalDeliveryBoyCommission: data?.data?.totalDeliveryBoyCommission ?? 0,
                });
            } catch (error) {
                console.error('Error fetching dashboard analytics:', error);
            }
        };
    
        fetchData();
    }, []);
    
    
    // Define the icons and labels
    const statsList = [
        { icon: <RestaurantMenuOutlinedIcon style={{ color: '#F21374' }} />, label: 'Total Caters', value: stats.totalCaters },
        { icon: <RestaurantIcon style={{ color: '#F2A774' }} />, label: 'Total Restaurants', value: stats.totalRestaurants },
        { icon: <LocalShippingIcon style={{ color: '#7AE582', }} />, label: 'Total Delivery Partners', value: stats.totalDeliveryPartners },
        { icon: <PeopleIcon style={{ color: '#65C6C4' }} />, label: 'Total Customers', value: stats.totalCustomers },
        { icon: <ShoppingCartOutlinedIcon style={{ color: '#FFB6C1' }} />, label: 'Fesste Mart', value: stats.fessteMart },
        { icon: <AccountBalanceWalletIcon style={{ color: '#99E7F5' }} />, label: 'Total Earnings', value: stats.totalEarnings.toFixed(2) },
        { icon: <TrendingUpIcon style={{ color: '#F16F6F' }} />, label: 'Today Earnings', value: stats.todayEarnings.toFixed(2) },
        { icon: <MonetizationOnIcon style={{ color: '#FFD700' }} />, label: 'Total Admin Commission', value: stats.totalAdminCommission },
        { icon: <LocalMallIcon style={{ color: '#7AE582' }} />, label: 'Total Restaurant Commission', value: stats.totalRestaurantCommission },
        { icon: <LocalMallIcon style={{ color: '#FF4500' }} />, label: 'Total Delivery Boy Commission', value: stats.totalDeliveryBoyCommission },
    ];

    return (
        <Paper elevation={0} style={{ padding: '10px', borderRadius: '5px' }}>
            <Grid container spacing={2}>
                {statsList.map((stat, index) => (
                    <Grid item xs={12} sm={6} md={2.4} key={index}>
                        <Box
                            display="flex"
                            alignItems="center"
                            style={{
                                backgroundColor: '#f9f9f9',
                                padding: '10px',
                                borderRadius: '10px',
                            }}
                        >
                            <Box mr={2}>{stat.icon}</Box>
                            <Box>
                                <Typography variant="h6" style={{ fontWeight: 'bold', color: '#333' }}>
                                    {stat.value}
                                </Typography>
                                <Typography variant="caption" style={{ color: '#777' }}>
                                    {stat.label}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

export default DashboardStatistics;
