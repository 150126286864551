// src/api/restaurantorders.js
import baseUrl from "../../config";

export const fetchRestaurantOrders = async () => {
  try {
    const response = await fetch(`${baseUrl}getAllRestOrders`);
    const data = await response.json();

    // Map the API response to match the columns
    const mappedData = data.map((order, index) => ({
      id: order?._id,
      siNo: index + 1,
      restaurantId: order?.restaurantId?._id,
      orderId: order?.restaurant_orderId,
      customerName: order?.customer?.fullName,
      orderType: order?.status === 'Delivered' ? 'Delivery' : 'Pickup',
      deliveryPerson: 'N/A',
      restaurant: order?.restaurantId?.restName,
      status: order?.status,
      quantity:order?.items?.[0]?.quantity,
      itemOrdered:order?.items.map(item => item.itemId?.restItemName).join(', '),
      deliveryDate:new Date(order?.createdAt).toLocaleDateString(),
      rejectReason: order?.rejectReason,
      fipaymentStatus:order?.finalPaymentSchedule?.finalPaymentSchedule?.status,
      date: new Date(order?.createdAt).toLocaleDateString(),
      address: `${order?.restaurantId?.restAddress?.addressLine1},${order?.restaurantId?.restAddress?.addressLine2}, ${order?.restaurantId?.restAddress?.city}, ${order?.restaurantId?.restAddress?.state} - ${order?.restaurantId?.restAddress?.postalCode}`,
      shippingAddress: `${order?.deliveryAddress?.street}, ${order?.deliveryAddress?.city}, ${order?.deliveryAddress?.state} - ${order?.deliveryAddress?.postalCode}`,
      paymentMode: 'N/A',
      total: `₹${order?.grossAmount}`,
      totalAmount:`₹${order?.totalAmount}`,
      paymentStatus: order?.paymentStatus,
      orderDate: new Date(order?.createdAt).toLocaleDateString(),
      deliveredBy: order?.deliveryPerson || 'Not Assigned',
      discountObtained: order?.discountAmount || 0,
      couponCodeUsed: order?.couponCode || 'None',
      discountAmount: order?.discountAmount || 0,
      userId: order?.customer?._id
    }));
    console.log('mappedData', mappedData);

    return mappedData;
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};

// src/api/orders/orderDetails.js
export const fetchRestaurantOrderDetails = async (orderId) => {
  const response = await fetch(`${baseUrl}restorders/${orderId}`);
  if (!response.ok) {
    throw new Error('Failed to fetch order details');
  }
  const data = await response.json();
  return data;
};


export const fetchRestaurantOrdersById = async (restaurantId) => {
  try {
    const response = await fetch(`${baseUrl}orders/restaurant/${restaurantId}`);
    const data = await response.json();

    // Map the API response to match the columns
    const mappedData = data.map((order, index) => ({
      id: order?._id,
      siNo: index + 1,
      restaurantId: order?.restaurantId?._id,
      orderId: order?.restaurant_orderId,
      customerName: order?.customer?.fullName,
      orderType: order?.status === 'Delivered' ? 'Delivery' : 'Pickup',
      deliveryPerson: 'N/A',
      restaurant: order?.restaurantId?.restName,
      status: order?.status,
      quantity:order?.items?.[0]?.quantity,
      itemOrdered:order?.items.map(item => item.itemId?.restItemName).join(', '),
      deliveryDate:new Date(order?.createdAt).toLocaleDateString(),
      rejectReason: order?.rejectReason,
      finalpaymentStatus:order?.finalPaymentSchedule?.finalPaymentSchedule?.status,
      date: new Date(order?.createdAt).toLocaleDateString(),
      address: `${order?.restaurantId?.restAddress?.addressLine1},${order?.restaurantId?.restAddress?.addressLine2}, ${order?.restaurantId?.restAddress?.city}, ${order?.restaurantId?.restAddress?.state} - ${order?.restaurantId?.restAddress?.postalCode}`,
      shippingAddress: `${order?.deliveryAddress?.street}, ${order?.deliveryAddress?.city}, ${order?.deliveryAddress?.state} - ${order?.deliveryAddress?.postalCode}`,
      paymentMode: 'N/A',
      total: `₹${order?.grossAmount}`,
      totalAmount:`₹${order?.totalAmount}`,
      paymentStatus: order?.paymentStatus,
      orderDate: new Date(order?.createdAt).toLocaleDateString(),
      deliveredBy: order?.deliveryPerson || 'Not Assigned',
      discountObtained: order?.discountAmount || 0,
      couponCodeUsed: order?.couponCode || 'None',
      discountAmount: order?.discountAmount || 0,
      userId: order?.customer?._id
    }));
    console.log('mappedData', mappedData);

    return mappedData;
  } catch (error) {
    console.error('Error fetching orders:', error);
    throw error;
  }
};
