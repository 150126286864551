import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  styled,
  Paper,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AdminDrawer from "../../components/AdminDrawer/AdminDrawer";
import AdvancedDataTable from "../../components/DataTables/AdvancedDataTable";
import avatarWomen from "../../assets/avatarwomen.jpg";
import { fetchDeliveryPartners, deleteDeliveryPartnerById } from "../../api/deliveryPartner/deliveryPartner";
import BreadcrumbsComponent from "../../components/ResuableComponents/BreadCrumb";
import ConfirmDialog from "../../components/ResuableComponents/ConfirmDialog";
import CustomSnackbar from "../../components/CustomeSnackBar/CustomSnackbar";

const TableContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: "100vh",
  height: "650px",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[0],
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
    height: "auto",
  },
}));

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: theme.spacing(2),
}));

const DeliveryPartners = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleAddDeliveryPartnerClick = () => {
    navigate("/add-delivery-partners");
  };

  // Define the handleViewDetails function
  const handleViewDetails = (deliveryPartner) => {

    navigate(`/delivery-partner-details/${deliveryPartner}`, {
      state: deliveryPartner,
    }); // Pass the delivery partner data through state
  };
  const handleEditClick = (rowData) => {
    navigate(`/edit-delivery-partners/${rowData}`, { state: rowData });
  };

  const handleCloseDialog1 = () => {
    setOpenDialog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDeleteClick = (rowData) => {
    setSelectedRow(rowData);
    setOpenDialog(true);

  };
  useEffect(() => {
    const loadData = async () => {
      const fetchedData = await fetchDeliveryPartners();
      const transformedData = fetchedData.map((item) => {
        const profileImage = item.documents?.find(
          (doc) => doc.type === "profileImage"
        )?.url;
        const aadharFront = item.documents?.find(
          (doc) => doc.type === "aadharFront"
        )?.url;
        const aadharBack = item.documents?.find(
          (doc) => doc.type === "aadharBack"
        )?.url;
        const panCard = item.documents?.find(
          (doc) => doc.type === "panCard"
        )?.url;
        const drivingLicenseFront = item.documents?.find(
          (doc) => doc.type === "drivingLicenseFront"
        )?.url;
        const drivingLicenseBack = item.documents?.find(
          (doc) => doc.type === "drivingLicenseBack"
        )?.url;
        const vehicleFrontDoc = item.documents?.find(
          (doc) => doc.type === "vehicleFrontDoc"
        )?.url;
        const vehicleBackDoc = item.documents?.find(
          (doc) => doc.type === "vehicleBackDoc"
        )?.url;

        return {
          id: item._id,
          dpId:item.dpId,
          fullName: item.fullName,
          email: item.email,
          phoneNumber: item.phoneNumber,
          secondaryPhone: item.secondaryPhone,
          currentAddress: item.currentAddress
            ? `${item.currentAddress.addressLine1 || ""}, ${
                item.currentAddress.addressLine2 || ""
              }, ${item.currentAddress.city || ""} - ${
                item.currentAddress.postCode || ""
              }, ${item.currentAddress.state || ""}, ${
                item.currentAddress.country || ""
              }`
            : "Address not provided",
          permanentAddress: item.permanentAddress
            ? `${item.permanentAddress.addressLine1 || ""}, ${
                item.permanentAddress.addressLine2 || ""
              }, ${item.permanentAddress.city || ""} - ${
                item.permanentAddress.postCode || ""
              }, ${item.permanentAddress.state || ""}, ${
                item.permanentAddress.country || ""
              }`
            : "Address not provided",
          documents: item.documents,
          OnOffDuty: item.OnOffDuty.status,
          isOnDelivery: item.isOnDelivery,
          location: item.location,
          dob: item.dob,
          vehicleRegistrationNumber: item.vehicleRegistrationNumber,
          vehicleType: item.vehicleType,
          createdAt: item.createdAt,
          updatedAt: item.updatedAt,
          profileImage: profileImage || avatarWomen,
          aadharFront: aadharFront || avatarWomen,
          aadharBack: aadharBack || avatarWomen,
          panCard: panCard || avatarWomen,
          drivingLicenseFront: drivingLicenseFront || avatarWomen,
          drivingLicenseBack: drivingLicenseBack || avatarWomen,
          vehicleFrontDoc: vehicleFrontDoc || avatarWomen,
          vehicleBackDoc: vehicleBackDoc || avatarWomen,
        };
      });
      setData(transformedData);
    };

    loadData();
  }, []);

  const columns = [
    { field: "dpId", headerName: "Partner ID", minWidth:200, sortable: false },
    {
      field: "profileImage",
      headerName: "Photo",
      minWidth:200,
      sortable: false,
      renderCell: (params) => (
        <img
          src={params.value || avatarWomen}
          alt="Item"
          style={{
            width: "45px",
            height: "45px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      ),
    },
    { field: "fullName", headerName: "Full Name", minWidth:200, sortable: true },
    { field: "email", headerName: "Email", minWidth:200, sortable: true },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      minWidth:200,
      sortable: true,
    },
    {
      field: "secondaryPhone",
      headerName: "Secondary Contact",
      minWidth:200,
      sortable: true,
    },
    {
      field: "OnOffDuty",
      headerName: "Duty Status",
      minWidth:200,
      sortable: true,
      renderCell: (params) => (
        <Chip
        label={params.value ? "On-Duty" : "Off-Duty"}
          color={params.value ? "success" : "warning"}
        />
      ),
    },
    {
      field: "isOnDelivery",
      headerName: "Current Status",
      minWidth:200,
      sortable: true,
      renderCell: (params) => (
        <Chip
          label={params.value ? "Delivering" : "Idle"}
          color={params.value ? "success" : "warning"}
        />
      ),
    },

    {
      field: "Actions",
      headerName: "Actions",
      minWidth:200,
      sortable: false,
      renderCell: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip title="View">
            <IconButton sx={{ color: "#87CEEB", marginRight: "8px" }} onClick={() => handleViewDetails(params.row.id)}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              color="success"
              onClick={() => handleEditClick(params.row.id)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton color="error"
              onClick={() => handleDeleteClick(params.row)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const confirmDelete = async (deleteReason) => {
    if (selectedRow) {
      try {
        await deleteDeliveryPartnerById(selectedRow.id, deleteReason); // Pass the deleteReason here
        // Update the data by filtering out the deleted caterer
        setData(data.filter((row) => row.id !== selectedRow.id));
        setSnackbarMessage("Caterer deleted successfully");
        setSnackbarOpen(true); // Show snackbar for success
      } catch (error) {
        console.error("Error deleting Delivery Partner:", error);
        setSnackbarMessage("Failed to delete Delivery Partner");
        setSnackbarOpen(true); // Show snackbar for error
      } finally {
        setOpenDialog(false);
      }
    }
  };


  return (
    <div style={{ backgroundColor: "#f2f2f2", minHeight: "100vh" }}>
      <AdminDrawer>
        <Box display="flex" mb={3}>
          <Typography variant="h4" fontWeight="bolder">
            Delivery Partners
          </Typography>
          <Box ml={3} mt={1}>
            <BreadcrumbsComponent />
          </Box>
        </Box>

        <TableContainer>
          <ButtonContainer>
            <Button
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              onClick={handleAddDeliveryPartnerClick}
            >
              Add Delivery Partner
            </Button>
          </ButtonContainer>
          <AdvancedDataTable
            rows={data}
            columns={columns}
            onAddClick={handleAddDeliveryPartnerClick}
          />
        </TableContainer>
        <ConfirmDialog
          open={openDialog}
          onClose={handleCloseDialog1}
          onConfirm={confirmDelete}
          title="Confirm Deletion"
          content="Are you sure you want to delete this Delivery Partner?"
        />
        <CustomSnackbar
          open={snackbarOpen}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          severity={
            snackbarMessage.toLowerCase().includes("successfully")
              ? "success"
              : "error"
          }
        />
      </AdminDrawer>
    </div>
  );
};

export default DeliveryPartners;
