import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawer from "../../../../components/AdminDrawer/AdminDrawer";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  IconButton,
  Chip,
  Tooltip,
  Typography,
  styled,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box
} from "@mui/material";
import DataTable from "../../../../components/DataTables/DataTable";
import { deleteFessteMartFlowers } from "../../../../api/servicePartners/fesstemart/fessteMartRegistrationApis";
import ConfirmDialog from "../../../../components/ResuableComponents/ConfirmDialog";
import CustomSnackbar from "../../../../components/CustomeSnackBar/CustomSnackbar";
import { useDialog } from '../../../../hooks/useDialog';
import { renderCellWithMore } from '../../../../components/ResuableComponents/ViewMoreComponents/ViewMorePopup';
import baseUrl from "../../../../config";
import BreadcrumbsComponent from "../../../../components/ResuableComponents/BreadCrumb";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const TableContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  maxHeight: "100vh",
  height: "650px",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[0],
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
    height: "auto",
  },
}));

const Flowers = () => {
  const { dialogContent, dialogOpen, handleOpenDialog, handleCloseDialog } =
    useDialog();

  const navigate = useNavigate();
  const [flowersData, setFlowersData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [snackbarOpenClipCard, setSnackbarOpenClipCard] = useState(false);

  useEffect(() => {
    // Fetch flower data from the API
    const fetchFlowers = async () => {
      try {
        const response = await fetch(`${baseUrl}flowerSp`);
        const data = await response.json();
        if (response.ok) {
          const formattedData = data.map((item, index) => ({
            id: index + 1,
            flowersId: item?._id,
            flowID: item?.flowersID,
            Name: item.serviceProvider?.spName,
            Email: item.serviceProvider?.spEmail,
            businessImage: item?.businessProfilePhoto[0],
            Contact: item.serviceProvider?.spPhone,
            address: `${item?.address?.addressLine1}, ${item?.address?.addressLine2},${item?.address?.landmark},${item?.address?.city}, ${item?.address?.postalCode},${item?.address?.state},${item?.address?.country}`,
            fmID: item.fessteMart?.fmID,
            fessteMartId: item.fessteMart?._id,
            BusinessName: item.businessName,
            Status: item.availability ? "Active" : "In-Active",
            BusinessPhone: item.businessPhoneNumber,
            CreatedOn: new Date(item.createdAt).toLocaleString(),
          }));
          setFlowersData(formattedData);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching flowers data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFlowers();
  }, []);

  const handleViewClick = (fmID, flowersId) => {
    navigate(`/flowers-details/${fmID}`, { state: { flowersId } }); // Navigate to the details page with flowersId
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    setSnackbarOpenClipCard(true); // Show Snackbar when text is copied
  };

  const handleDelete = async () => {
    try {
      const response = await deleteFessteMartFlowers(itemToDelete);

      if (response) {
        setSnackbarSeverity("success");
        setSnackbarMessage(
          response.message || "Fesste Mart deleted successfully"
        );
        // Close the confirmation dialog after successful deletion
        setOpenConfirmDialog(false);

        // Optionally refresh your data or update the formatdata state here
        setFlowersData((prevData) =>
          prevData.filter((item) => item.fessteMartId !== itemToDelete)
        );
      } else {
        setSnackbarSeverity("warning");
        setSnackbarMessage(
          response.message || "Operation succeeded but with warnings"
        );
      }
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage("Failed to delete Fesste Mart");
      console.error("Error during deletion:", error);
    } finally {
      setSnackbarOpen(true); // Show snackbar after API call
    }
  };

  const handleEditClick = (rowData) => {
    navigate(`/edit-flowers/${rowData}`, { state: rowData });
  };

  const handleDeleteClick = (fessteMartId) => {
    setOpenConfirmDialog(true);
    // Optionally store fessteMartId to use it after confirmation
    setItemToDelete(fessteMartId);
  };

  const columns = [
    {
      field: "flowID",
      headerName: "ID",
      minWidth: 10,
      sortable: false,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleViewClick(params.row.fmID, params.row.flowersId)
            }
          >
            {text}
          </div>
        );
      },
    },
    // { field: "fmID", headerName: "FM ID", minWidth: 140, sortable: true },
    {
      field: "businessImage",
      headerName: "Business Image",
      type: "image",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center", // Horizontally center the image
            alignItems: "center", // Vertically center the image
            width: "100%", // Ensure the div takes full width of the cell
            height: "100%", // Ensure the div takes full height of the cell
          }}
        >
          <img
            src={
              params.value !== "N/A"
                ? params.value
                : "placeholder-image-url.jpg"
            } // Placeholder for broken images
            alt="Item"
            style={{
              width: "100px", // Fixed width
              height: "60px", // Fixed height
              borderRadius: "4px", // Rounded corners
              objectFit: "cover", // Cover the area while maintaining aspect ratio
            }}
          />
        </div>
      ),
    },
    {
      field: "BusinessName",
      headerName: "Business Name",
      minWidth: 250,
      sortable: true,
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string

        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleViewClick(params.row.fmID, params.row.flowersId)
            }
          >
            {text}
          </div>
        );
      },
    },
    {
      field: "BusinessPhone",
      headerName: "Business Contact",
      minWidth: 200,
      sortable: true,
    },
    { field: "Name", headerName: "Owner Name", minWidth: 250, sortable: true },
    {
      field: "Email",
      headerName: "Owner Email",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "Contact",
      headerName: "Owner Contact",
      minWidth: 200,
      sortable: true,
    },
    {
      field: 'address',
      headerName: 'Address',
      minWidth: 350,
      flex: 1, // Makes the column responsive based on available space
      renderCell: (params) => {
        const text = params.value || ""; // Ensure value is a string
        return (
          <Box
            sx={{
              display: 'flex',         // Use flexbox for alignment
              justifyContent: 'space-between', // Align text on the left and icon on the right
              alignItems: 'center',    // Align vertically to the center
              whiteSpace: 'normal',    // Allow the text to wrap to the next line
              wordWrap: 'break-word',  // Break long words into the next line
              overflow: 'visible',     // Ensure the content isn't hidden
              textOverflow: 'clip',    // Avoid ellipsis and display all text
              lineHeight: 1.2,         // Adjust line height for better readability
              height: '100%',          // Ensure the content fills the cell vertically
              padding: '12px 10px',    // Add padding for better spacing within the cell
            }}
          >
            <Box sx={{ flexGrow: 1, paddingRight: '10px' }}>
              {/* Render the address value with "Show More" functionality */}
              {renderCellWithMore(text, handleOpenDialog)}
            </Box>
            <IconButton
              aria-label="copy to clipboard"
              size="small"
              onClick={() => handleCopy(params.value)}  // Trigger copy action
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: "CreatedOn",
      headerName: "Created On",
      minWidth: 200,
      sortable: true,
    },

    {
      field: "Status",
      headerName: "Status",
      minWidth: 200,
      sortable: true,
      renderCell: (params) => (
        <Chip
          label={params.value}
          color={params.value === "Active" ? "success" : "warning"}
        />
      ),
    },

    {
      field: "Actions",
      headerName: "Actions",
      minWidth: 160,
      sortable: false,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>

          <Tooltip title="View">
            <IconButton
              sx={{ color: "#87CEEB", marginRight: "8px" }}
              onClick={() =>
                handleViewClick(params.row.fmID, params.row.flowersId)
              }
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              color="success"
              onClick={() => handleEditClick(params.row.flowersId)}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row.fessteMartId)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  return (
    <div style={{ backgroundColor: "#f2f2f2" }}>
      <AdminDrawer>
      <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h4" fontWeight="bolder" sx={{mr: 3}}>
            Flowers
          </Typography>
          <BreadcrumbsComponent />
        </Box>
        <TableContainer>
          <DataTable rows={flowersData} columns={columns} />
        </TableContainer>
        <ConfirmDialog
          open={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          onConfirm={handleDelete}
          title="Confirm Deletion"
          content="Are you sure you want to delete this Flower?"
        />
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          severity={snackbarSeverity}
          onClose={handleCloseSnackbar}
        />
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>Details</DialogTitle>
          <DialogContent>
            <Typography>{dialogContent}</Typography>
          </DialogContent>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </Dialog>
      </AdminDrawer>
    </div>
  );
};

export default Flowers;
